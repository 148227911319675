import React, { FC } from 'react';
import { Logo } from '../../components/Logo';

type RegistrationIntroProps = {
  formTitle: string;
};

export const RegistrationIntro: FC<RegistrationIntroProps> = ({ formTitle, children }) => {
  return (
    <div className='registration_intro'>
      <div>
        <div>
          <Logo />
        </div>
        <h2 className='registration_title'>{formTitle}</h2>
        <p>Thank you for joining the Ballroom Connection.</p>
        <p>To get the most out of the app complete your dancer profile.</p>
      </div>
      {children}
    </div>
  );
};
