import { useState } from 'react';

export const useFormFields = <T>(initialValues: T) => {
  const [formState, setFormState] = useState<T>(initialValues);

  const resetFormState = (initial?: T) => {
    setFormState(initial || ({} as T));
  };

  const removeFormValue = (key: keyof T) => {
    setFormState((prev: T) => {
      return { ...prev, [key]: undefined };
    });
  };

  const setFormValue = (key: keyof T, value: T[keyof T]) => {
    setFormState((prev: T) => {
      return { ...prev, [key]: value };
    });
  };

  const createChangeHandler = (key: keyof T) => (value: T[keyof T]) => {
    setFormState((prev: T) => {
      return { ...prev, [key]: value };
    });
  };
  return { formState, resetFormState, setFormValue, removeFormValue, createChangeHandler };
};
