import React, { FC, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchFavouriteEventIds,
  addEventIdToFavourites,
  removeEventIdFromFavourites,
  setUserId,
  selectFavouriteEvents,
} from '../../features/favouriteEventsSlice';
import { BsHeart, BsHeartFill } from 'react-icons/bs';
import { UserContext } from '../../contexts/UserContext';

const iconSize = 20;

type AddFavouriteProps = {
  eventId: string;
};

export const AddFavourite: FC<AddFavouriteProps> = ({ eventId }) => {
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();
  const favouriteEvents = useSelector(selectFavouriteEvents);

  useEffect(() => {
    if (user) {
      dispatch(setUserId(user.uid));
      dispatch(fetchFavouriteEventIds());
    }
  }, [user]);

  const handleToggleFavouriteEvent = () => {
    if (favouriteEvents.includes(eventId)) {
      dispatch(removeEventIdFromFavourites(eventId));
    } else {
      dispatch(addEventIdToFavourites(eventId));
    }
  };

  return (
    <div onClick={handleToggleFavouriteEvent}>
      {favouriteEvents.includes(eventId) ? <BsHeartFill size={iconSize} /> : <BsHeart size={iconSize} />}
    </div>
  );
};
