import React, { FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useLayoutConfig } from '../../hooks/useLayoutConfig';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { configs } from '../../layoutConfig';

import '../layouts.scss';

export const LoggedInLayout: FC = () => {
  const location = useLocation();
  const { pageData } = useLayoutConfig(configs, location.pathname);
  const classMap = ['layout-container', 'layout-container_logged-in'];

  if (pageData.bgVariant) {
    classMap.push(`layout-container_logged-in--${pageData.bgVariant}`);
  }
  return (
    <>
      <Header
        title={pageData.title}
        linkLeft={pageData?.linkLeft}
        linkRight={pageData?.linkRight}
        bgVariant={pageData?.bgVariant}
      ></Header>
      <div className={classMap.join(' ')} data-testid='layout-container'>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};
