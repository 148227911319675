import React, { FC, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DanceEvent } from '@bcx-tech/tbc-types';
import { EventTiles } from '../../components/EventTiles';
import { UserContext } from '../../contexts/UserContext';

import { fetchFavouriteEventIds, setUserId, selectFavouriteEvents } from '../../features/favouriteEventsSlice';
import { selectSpecificEvents, fetchSpecificEvents } from '../../features/eventsSlice';

import { RootState } from '../../store';

export const FavouriteEvents: FC = () => {
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();
  const favouriteEvents = useSelector(selectFavouriteEvents);
  const events: DanceEvent[] = useSelector((state: RootState) => selectSpecificEvents(state, favouriteEvents || []));

  useEffect(() => {
    if (user) {
      dispatch(setUserId(user.uid));
      dispatch(fetchFavouriteEventIds());
    }
    if (favouriteEvents) {
      dispatch(fetchSpecificEvents(favouriteEvents));
    }
  }, [user]);

  return <>{!!events.length && <EventTiles events={events} hideDate={true} />}</>;
};
