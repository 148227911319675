import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { DanceStyle } from '@bcx-tech/tbc-types';
import { useProfile } from '../../hooks';
import { setCompetitionPreferences } from '../../features/dancerProfileSlice';
import { CompetitionPreferencesField } from '../CompetitionPreferencesField';

import { DancerProfileFormProps, DancerProfileFormState } from '../../interfaces';

import './ProfileForms.scss';

export const PreferencesForm: FC<DancerProfileFormProps> = ({
  validated,
  dancerProfile,
  onSubmit,
  formTitle,
  context,
  children,
}) => {
  const { formState, handleChangeCompetitionPreference, handleRemoveCompetitionPreference, handleAddPreferenceForm } =
    useProfile(dancerProfile);
  const dispatch = useDispatch();

  const submitHandler = (formState: DancerProfileFormState) => {
    if (formState.competitionPreferences) {
      dispatch(setCompetitionPreferences(formState.competitionPreferences));
    }
  };

  const baseClass = 'profile-form';

  return (
    <Form
      className={baseClass}
      noValidate
      validated={validated}
      onSubmit={(e) => onSubmit(e, formState, submitHandler)}
    >
      <div className={`${baseClass}_container`}>
        {formTitle && <h2 className={`${baseClass}_title`}>{formTitle}</h2>}
        {!!formState.danceStyles?.length && (
          <CompetitionPreferencesField
            danceStyle={context?.danceStyle as DanceStyle}
            danceStyles={formState.danceStyles}
            handleChangeCompetitionPreference={handleChangeCompetitionPreference}
            handleRemoveCompetitionPreference={handleRemoveCompetitionPreference}
            handleAddPreferenceForm={handleAddPreferenceForm}
            competitionPreferences={formState.competitionPreferences}
          />
        )}
      </div>
      {children}
    </Form>
  );
};
