import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { MdCake } from 'react-icons/md';
import { DateOfBirthField } from '@bcx-tech/weave';
import { useProfile } from '../../hooks';
import { setDateOfBirth } from '../../features/dancerProfileSlice';

import { DancerProfileFormProps, DancerProfileFormState } from '../../interfaces';

import './ProfileForms.scss';

export const DOBForm: FC<DancerProfileFormProps> = ({ dancerProfile, onSubmit, validated, formTitle, children }) => {
  const { formState, createChangeHandler } = useProfile({ ...dancerProfile });
  const dispatch = useDispatch();

  const submitHandler = (formState: DancerProfileFormState) => {
    dispatch(setDateOfBirth(formState.dateOfBirth));
  };

  const baseClass = 'profile-form';

  return (
    <Form
      className={baseClass}
      noValidate
      validated={validated}
      onSubmit={(e) => onSubmit(e, formState, submitHandler)}
    >
      <div className={`${baseClass}_container`}>
        <MdCake size={48} className={`${baseClass}_form-icon`} />
        {formTitle && <h2 className={`${baseClass}_title`}>{formTitle}</h2>}
        <DateOfBirthField
          dob={dancerProfile?.dateOfBirth}
          showAge={true}
          onChange={createChangeHandler('dateOfBirth')}
        />
        <p>You can change this later by contacting customer service.</p>
      </div>
      {children}
    </Form>
  );
};
