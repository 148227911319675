enum CommonLinks {
  settings = 'settings',
  back = 'back',
  backToEvents = 'backToEvents',
  favouriteEvents = 'favouriteEvents',
  profile = 'profile',
  profileEdit = 'profileEdit',
  editProfileLink = 'editProfileLink',
}

export const configs = [
  {
    path: '/',
    bgVariant: 'off-white',
    linkRight: CommonLinks.settings,
  },
  {
    path: '/settings',
    title: 'Settings',
    linkLeft: CommonLinks.back,
  },
  {
    path: '/profile',
    title: 'Profile',
    linkLeft: CommonLinks.back,
    linkRight: CommonLinks.editProfileLink,
  },
  {
    path: '/profile/media',
    title: 'Photos and Videos',
    linkLeft: CommonLinks.profile,
    linkRight: CommonLinks.editProfileLink,
  },
  {
    path: '/profile/edit',
    title: 'Edit Profile',
    linkLeft: CommonLinks.profile,
  },
  {
    path: '/profile/edit/personal-details',
    title: 'Edit Personal Details',
    linkLeft: CommonLinks.profileEdit,
  },
  {
    path: '/profile/edit/profile',
    title: 'Edit Profile',
    linkLeft: CommonLinks.profileEdit,
  },
  {
    path: '/profile/edit/location',
    title: 'Update Location',
    linkLeft: CommonLinks.profileEdit,
  },
  {
    path: '/profile/edit/images',
    title: 'Edit Images',
    linkLeft: CommonLinks.profileEdit,
  },
  {
    path: '/profile/edit/profile-image',
    title: 'Edit Profile Photo',
    linkLeft: CommonLinks.profileEdit,
  },
  {
    path: '/profile/edit/videos',
    title: 'Edit Videos',
    linkLeft: CommonLinks.profileEdit,
  },
  {
    path: '/profile/edit/dance-styles',
    title: 'Edit Dance Styles',
    linkLeft: CommonLinks.profileEdit,
  },
  {
    path: '/profile/edit/competition-preferences',
    title: 'Edit Competition Preferences',
    linkLeft: CommonLinks.profileEdit,
  },
  {
    path: '/profile/edit/partner-search',
    title: 'Edit Partner Search Preferences',
    linkLeft: CommonLinks.profileEdit,
  },
  {
    path: '/partner-search',
    title: 'Partner Search',
    linkLeft: CommonLinks.back,
  },
  {
    path: '/my-connections',
    title: 'My Connections',
    linkLeft: CommonLinks.back,
  },
  {
    path: '/achievements',
    title: 'Achievements',
    linkLeft: CommonLinks.back,
  },
  {
    path: '/events',
    title: 'Events',
    linkRight: CommonLinks.favouriteEvents,
  },
  {
    pathRegex: /\/events\/.*/,
    bgVariant: 'off-white',
    title: 'Event Details',
    linkLeft: CommonLinks.backToEvents,
  },
  {
    path: '/favourite-events',
    title: 'Favourite Events',
    linkLeft: CommonLinks.backToEvents,
  },
];
