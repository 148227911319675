import React, { FC } from 'react';
import { PrimaryButton } from '../../components/Buttons';
import { Container } from 'react-bootstrap';

export const NotFoundPage: FC = () => (
  <Container fluid className='p-3 h-100 d-flex flex-column justify-content-between'>
    <div>
      <h1>Page not found</h1>
      <p>
        Looks like you found a screen that doesn&apos;t exist, or there might be a problem with the screen you are
        trying to access.
      </p>
    </div>
    <PrimaryButton label='Go back to home' href='/' />
  </Container>
);
