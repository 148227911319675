import React, { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { GrNext, GrPrevious } from 'react-icons/gr';
import { Loader } from '@bcx-tech/weave';
import { ImageReference } from '@bcx-tech/tbc-types';
import './PreviewImages.scss';

type PreviewImageProps = {
  handleOnHide: () => void;
  showModal: boolean;
  images: ImageReference[];
  id: string;
};

export const PreviewImages: FC<PreviewImageProps> = ({ handleOnHide, showModal, images, id }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currPosition, setCurrPosition] = useState(images.findIndex((i) => i.id === id) ?? 0);

  const onImageLoad = () => {
    setIsLoading(false);
  };

  const next = () => {
    setCurrPosition(currPosition === images.length - 1 ? currPosition : currPosition + 1);
  };

  const prev = () => {
    setCurrPosition(currPosition === 0 ? currPosition : currPosition - 1);
  };

  return (
    <Modal
      id='preview-modal'
      show={showModal}
      fullscreen={true}
      onHide={handleOnHide}
      centered
      size='lg'
      className='preview-modal'
    >
      <Modal.Header closeButton closeVariant='white'></Modal.Header>
      <div className='h-100 d-flex flex-row align-items-center jusify-content-between'>
        {isLoading && (
          <div className='w-100 d-flex flex-column justify-content-center'>
            <Loader />
          </div>
        )}
        <div className='w-100'>
          <img
            className='img-fluid w-100'
            src={images[currPosition].mediaURL}
            alt={images[currPosition].name}
            onLoad={onImageLoad}
          />
          {currPosition > 0 && (
            <div className='preview-modal_navigation preview-modal_navigation_prev' onClick={prev}>
              <GrPrevious size={24} />
            </div>
          )}
          {currPosition < images.length - 1 && (
            <div className='preview-modal_navigation preview-modal_navigation_next' onClick={next}>
              <GrNext size={24} />
            </div>
          )}
        </div>
      </div>
      <Modal.Footer />
    </Modal>
  );
};
