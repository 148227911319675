import React, { FC } from 'react';
import { BsCheck } from 'react-icons/bs';

type ToggleListItemProps = {
  label: string;
  isSelected: boolean;
};

export const ToggleListItem: FC<ToggleListItemProps> = ({ label, isSelected }) => {
  return (
    <div className='d-flex justify-content-between align-items-start'>
      <div className='label'>{label}</div>
      <div className='selected'>{isSelected ? <BsCheck /> : ''}</div>
    </div>
  );
};
