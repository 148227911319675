import React, { useState, FC, ChangeEvent } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';

type PasswordFieldProps = {
  onChangeText: (value: string) => void;
  validator?: (value: string) => boolean;
};

export const PasswordField: FC<PasswordFieldProps> = ({ onChangeText, validator }) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const onPasswordInput = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    // validate it
    if (validator && !validator(target.value)) {
      //if (!isValidPassword(target.value)) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
      onChangeText(target.value);
    }
  };

  return (
    <FormGroup className='mb-3'>
      <FormControl
        type='password'
        placeholder='Password'
        aria-label='Password'
        onChange={onPasswordInput}
        autoComplete='current-password'
        isInvalid={isInvalid}
      />
      <div className='invalid-feedback'>
        Your password must be a minimum of 8 characters long, and contain letters and numbers.
      </div>
    </FormGroup>
  );
};
