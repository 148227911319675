import React, { FC } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getOptionsMap } from '@bcx-tech/frontend-core';
import { DanceEvent } from '@bcx-tech/tbc-types';
import { AddressView } from '@bcx-tech/weave';
import './EventTiles.scss';
import { DateView } from '../DateView';

const eventTypeChoices = [
  {
    value: 'COMPETITION',
    label: 'Competition',
  },
  {
    value: 'TRAININGCAMP',
    label: 'Training',
  },
];

const eventTypeOptions = getOptionsMap(eventTypeChoices);
const formatEventType = (eventType: string) => eventTypeOptions[eventType];

type EventTileProps = {
  event: DanceEvent;
};

export const EventTile: FC<EventTileProps> = ({ event }) => {
  const classMap = ['event-tile', `event-tile--${event.eventType.toLowerCase()}`];
  return (
    <Link to={`/events/${event.id}`} className='event-tile-link'>
      <Card className={classMap.join(' ')}>
        <Card.Header className='event-tile_header d-flex justify-content-between'>
          <div>
            <DateView startDate={event.startDate} endDate={event.endDate}></DateView>
          </div>
          <div className='event-tile_event-type'>{formatEventType(event.eventType)}</div>
        </Card.Header>
        <Card.Body className='d-flex justify-content-between'>
          <div className='m-3'>
            <Card.Title>{event.title}</Card.Title>
            <AddressView shortView address={event.address}></AddressView>
          </div>
          {event.image && (
            <img className='event-tile_image' src={event.image.url} alt={event.title} title={event.title} />
          )}
        </Card.Body>
      </Card>
    </Link>
  );
};
