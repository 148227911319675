import { FC } from 'react';
import {
  EditPersonalDetailsForm,
  ImagesForm,
  ProfileImageForm,
  VideosForm,
  EditBioForm,
  EditDanceStylesForm,
  LocationForm,
  PreferencesForm,
  SeekPartnerForm,
} from '../../components/ProfileForms';
import { DancerProfileFormProps } from '../../interfaces';

export const profileEditFormConfigs: Record<
  string,
  { ProfileForm: FC<DancerProfileFormProps>; submitButtonLabel?: string }
> = {
  'personal-details': {
    ProfileForm: EditPersonalDetailsForm,
  },
  profile: {
    ProfileForm: EditBioForm,
  },
  location: {
    ProfileForm: LocationForm,
  },
  images: {
    ProfileForm: ImagesForm,
  },
  'profile-image': {
    ProfileForm: ProfileImageForm,
  },
  videos: {
    ProfileForm: VideosForm,
  },
  'dance-styles': {
    ProfileForm: EditDanceStylesForm,
  },
  'competition-preferences': {
    ProfileForm: PreferencesForm,
  },
  'partner-search': {
    ProfileForm: SeekPartnerForm,
  },
};
