import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { BsGlobeAmericas } from 'react-icons/bs';
import { LocationLookupField, LocationResult } from '@bcx-tech/weave';
import { Form } from 'react-bootstrap';
import { BsCompass } from 'react-icons/bs';
import { useProfile, useGeolocation } from '../../hooks';
import { setLocation, removeLocation } from '../../features/dancerProfileSlice';

import { DancerProfileFormProps, DancerProfileFormState } from '../../interfaces';

import './ProfileForms.scss';

export const LocationForm: FC<DancerProfileFormProps> = ({
  validated,
  dancerProfile,
  onSubmit,
  formTitle,
  children,
}) => {
  const { handleSetLocation, formState } = useProfile(dancerProfile);
  const { getReverseGeocode, mapLocationToAutocomplete, searchLocation } = useGeolocation();
  const dispatch = useDispatch();

  const submitHandler = (formState: DancerProfileFormState) => {
    if (formState.location) {
      dispatch(setLocation(formState.location));
    } else {
      dispatch(removeLocation());
    }
  };

  const baseClass = 'profile-form';

  const reverseGeocode = async (position: { lat: number; long: number }): Promise<LocationResult> => {
    return (await getReverseGeocode(position)) as LocationResult;
  };

  return (
    <Form
      className={baseClass}
      noValidate
      validated={validated}
      onSubmit={(e) => onSubmit(e, formState, submitHandler)}
    >
      <div className={`${baseClass}_container`}>
        <BsGlobeAmericas size={48} className={`${baseClass}_form-icon`} />
        {formTitle && <h2 className={`${baseClass}_title`}>{formTitle}</h2>}
        <LocationLookupField
          onChange={handleSetLocation}
          fetchItems={searchLocation}
          reverseGeocode={reverseGeocode}
          currentValue={formState.location ? mapLocationToAutocomplete(formState.location) : undefined}
          ButtonIcon={BsCompass}
        />
      </div>
      {children}
    </Form>
  );
};
