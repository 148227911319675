import { combineReducers, configureStore } from '@reduxjs/toolkit';
import dancerProfileReducer from './features/dancerProfileSlice';
import danceEventSearchReducer from './features/danceEventSearchSlice';
import eventsReducer from './features/eventsSlice';
import favouriteEventsReducer from './features/favouriteEventsSlice';

export const rootReducer = combineReducers({
  events: eventsReducer,
  favouriteEvents: favouriteEventsReducer,
  dancerProfile: dancerProfileReducer,
  danceEventSearch: danceEventSearchReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
});

export default store;
