import React, { FC } from 'react';
import './ImageGrid.scss';

export const ImageGridItem: FC = ({ children }) => {
  return (
    <div className='image-grid-item'>
      <div className='image-grid-item_content'>{children}</div>
    </div>
  );
};

export const ImageGrid: FC = ({ children }) => {
  return <div className='image-grid'>{children}</div>;
};
