import React, { FC } from 'react';
import { ImageReference } from '@bcx-tech/tbc-types';
import { ProfilePhoto } from '../ProfilePhoto';
import './LoggedInUser.scss';

type LoggedInUserProps = {
  profilePhoto?: ImageReference;
  firstName?: string;
  lastName?: string;
};

export const LoggedInUser: FC<LoggedInUserProps> = ({ profilePhoto, firstName, lastName }) => {
  return (
    <div className='logged-in-user'>
      <div>
        <h3>Welcome back!</h3>
        <div>
          {firstName} {lastName}
        </div>
      </div>
      <div className='logged-in-user_image-container'>
        <ProfilePhoto imageReference={profilePhoto} />
      </div>
    </div>
  );
};
