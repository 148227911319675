import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSignOutUser } from '@bcx-tech/frontend-core';
import { PrimaryButton } from '../../components/Buttons';
import { resetDancerProfile } from '../../features/dancerProfileSlice';
import { resetEventSearch } from '../../features/danceEventSearchSlice';

export const SettingsView: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signOutUser } = useSignOutUser();

  const handleSignOut = async () => {
    window.newrelic.setUserId(null);
    navigate('/start');
    signOutUser();
    dispatch(resetDancerProfile());
    dispatch(resetEventSearch());
  };

  return (
    <>
      <PrimaryButton handleClick={async () => handleSignOut()} label='Sign out' />
    </>
  );
};
