import React, { FC } from 'react';
import './RowLabelListView.scss';

type RowLabelListViewProps = {
  label?: string;
  itemArray?: string[];
};

export const RowLabelListView: FC<RowLabelListViewProps> = ({ label, itemArray }) => (
  <>
    {itemArray && !!itemArray.length && (
      <div>
        {label && <span className='row-label-list-view_label'>{label}:</span>}
        <ul className='row-label-list-view_list'>
          {itemArray.map((item: string, i: number) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </div>
    )}
  </>
);
