import { countries } from 'countries-list';

export class CountryLookupError extends Error {
  countryCode: string;

  constructor(countryCode: string) {
    super(`${countryCode} is not a valid country code`);
    this.name = 'CountryLookupError';
    this.countryCode = countryCode;
  }
}

export const useCountries = (): { getCountryByCode: (countryCode: string) => string } => {
  const getCountryByCode = (countryCode: string) => {
    const country = countries[countryCode as keyof typeof countries];
    if (!country) {
      throw new CountryLookupError(countryCode);
    }
    return country.name;
  };
  return { getCountryByCode };
};
