import { eventsFeature } from '@bcx-tech/frontend-core';
import { DanceEvent } from '@bcx-tech/tbc-types';
import { RootState } from '../store';

const { slice, eventsAdapter } = eventsFeature;

export const { selectById: selectEvent } = eventsAdapter.getSelectors((state: RootState) => state.events);

export const selectSpecificEvents = (state: RootState, ids: string[]): DanceEvent[] => {
  const events: DanceEvent[] = [];
  Object.keys(state.events.entities).forEach((id) => {
    if (ids.includes(id)) {
      events.push(state.events.entities[id] as DanceEvent);
    }
  });
  return events.sort((a: DanceEvent, b: DanceEvent) => a.startDate - b.startDate);
};

export const { fetchSpecificEvents, fetchEvent } = eventsFeature;

export default slice.reducer;
