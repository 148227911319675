import React, { FC, useEffect, useState, FormEvent } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useForgotAndResetPassword } from '@bcx-tech/frontend-core';
import { AlertMessage, AlertMessageProps } from '../../components/AlertMessage';
import { PasswordField } from '../../components/PasswordField';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import { useFormFields } from '../../hooks';
import { isValidPassword } from '../../lib/utils';

import './ResetPassword.scss';
import { Logo } from '../../components/Logo';

type FormState = {
  password?: string;
};

export const ResetPassword: FC = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [alert, setAlert] = useState<AlertMessageProps & { isAlert: boolean }>({
    isAlert: false,
  });
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
  const { handleVerifyPasswordResetCode, handleConfirmPasswordReset } = useForgotAndResetPassword();
  const { formState, createChangeHandler } = useFormFields<FormState>({});
  const actionCode = params.get('actionCode');

  useEffect(() => {
    async function checkResetCode() {
      if (!actionCode) {
        setAlert({
          isAlert: true,
          type: 'danger',
          message: 'Missing or invalid action code',
        });
      } else {
        try {
          await handleVerifyPasswordResetCode(actionCode);
        } catch (error) {
          setAlert({ isAlert: true, type: 'danger', error: error as Error });
        }
      }
    }
    checkResetCode();
  }, [actionCode]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { password } = formState;
    if (actionCode && password) {
      try {
        await handleConfirmPasswordReset(actionCode, password);
        setPasswordChanged(true);
        setAlert({
          isAlert: true,
          type: 'info',
          message: `Your password has been updated`,
        });
      } catch (error) {
        setAlert({ isAlert: true, type: 'danger', error: error as Error });
        setPasswordChanged(false);
      }
    }
  };

  return (
    <div className='d-flex flex-column h-100'>
      <div>
        <Logo />
        <h1 className='reset-password_title'>Reset your password</h1>
      </div>
      {alert.isAlert && (
        <>
          <AlertMessage error={alert.error} message={alert.message} type={alert.type}></AlertMessage>
          {alert.type !== 'info' && (
            <PrimaryButton
              className='mb-1'
              handleClick={() => navigate('/auth/forgot-password')}
              label='Recover your password'
            />
          )}
        </>
      )}
      {passwordChanged && <PrimaryButton className='mb-1' handleClick={() => navigate('/auth/login')} label='Login' />}
      {!passwordChanged && !alert.isAlert && (
        <Form className='reset-password_form' onSubmit={handleSubmit}>
          <div className='d-flex flex-column align-items-center justify-content-between h-100'>
            <div>
              <h2 className='reset-password_subtitle'>Create a new password</h2>

              <PasswordField onChangeText={createChangeHandler('password')} validator={isValidPassword} />
            </div>
            <div className='w-75 d-flex flex-column align-items-center justify-content-between'>
              <PrimaryButton className='mb-1' type='submit' label='Save password' />
              <SecondaryButton label='Back' handleClick={() => navigate('/')} />
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};
