import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRemoteConfig } from '@bcx-tech/frontend-core';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import { Logo } from '../../components/Logo';
import brand from '../../assets/new-bcx-brand.png';
import './Start.scss';

export const Start: FC = () => {
  const { getConfigValue } = useRemoteConfig();
  const navigate = useNavigate();
  const enableSignup = getConfigValue('enableSignup');
  const isSignupEnabled = enableSignup.asBoolean();

  return (
    <div className='h-100 d-flex flex-column align-items-center justify-content-between'>
      <div>
        <div>
          <Logo />
        </div>
        <div>
          <img className='start_brand' src={brand} alt='The Ballroom Connection' />
        </div>
      </div>

      <div className='w-100'>
        {isSignupEnabled && (
          <>
            <PrimaryButton className='w-75 mb-3' label='Sign up' handleClick={() => navigate('/auth/signup')} />
          </>
        )}
        <SecondaryButton className='w-75 mb-4' label='Log in' handleClick={() => navigate('/auth/login')} />
      </div>
    </div>
  );
};
