import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { SingleChoiceField } from '@bcx-tech/weave';
import { Form } from 'react-bootstrap';
import { useProfile } from '../../hooks';
import { setIsOpenToTenDance } from '../../features/dancerProfileSlice';

import { DancerProfileFormProps, DancerProfileFormState } from '../../interfaces';

import './ProfileForms.scss';

export const TenDanceForm: FC<DancerProfileFormProps> = ({
  validated,
  dancerProfile,
  onSubmit,
  formTitle,
  children,
}) => {
  const { createChangeHandler, formState } = useProfile(dancerProfile);
  const dispatch = useDispatch();

  const submitHandler = (formState: DancerProfileFormState) => {
    if (formState.isOpenToTenDance !== undefined) {
      dispatch(setIsOpenToTenDance(formState.isOpenToTenDance));
    }
  };

  const baseClass = 'profile-form';

  const yesNoChoices = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ];

  return (
    <Form
      className={baseClass}
      noValidate
      validated={validated}
      onSubmit={(e) => onSubmit(e, formState, submitHandler)}
    >
      <div className={`${baseClass}_container`}>
        {formTitle && <h2 className={`${baseClass}_title`}>{formTitle}</h2>}
        <h2 className={`${baseClass}_subtitle`}>That&apos;s great!</h2>
        <div className='mt-5'>
          <h2 className={`${baseClass}_subtitle`}>Are you open to 10 Dance Competitions?</h2>
          <SingleChoiceField
            type='block-button'
            choice={formState.isOpenToTenDance !== undefined ? `${formState.isOpenToTenDance}` : undefined}
            onChoose={createChangeHandler('isOpenToTenDance')}
            choices={yesNoChoices}
            group='open-to-ten-dance'
          />
        </div>
      </div>
      {children}
    </Form>
  );
};
