import React, { FC, useEffect, useState } from 'react';
import { CountrySelectField, AutocompleteResult } from '@bcx-tech/weave';
import { ListGroup } from 'react-bootstrap';
import { ReverseGeocodeResult, LocationChoice } from '@bcx-tech/tbc-types';
import { useGeolocation, useCountries } from '../../hooks';
import { FiltersProps } from './@types/EventsFilters';
import { ToggleListItem } from '../ToggleListItem';

export const LocationFilters: FC<FiltersProps> = ({ formState, setFormValue }) => {
  const { getAddressFromGeolocation } = useGeolocation();
  const { getCountryByCode } = useCountries();
  const [geocodeLookupError, setGeocodeLookupError] = useState(false);
  const geocodeHandler = (result: ReverseGeocodeResult) => {
    setGeocodeLookupError(false);
    setFormValue('currentCountry', result.country.code);
  };
  const geocodeErrorHandler = () => {
    setGeocodeLookupError(true);
  };

  useEffect(() => {
    if (formState.location && formState.location === LocationChoice.Current && !formState.currentCountry) {
      setGeocodeLookupError(false);
      getAddressFromGeolocation(geocodeHandler, geocodeErrorHandler);
    }
  }, [formState.location]);

  const locationChoices = [
    { value: LocationChoice.Current, label: 'Current Country Only' },
    { value: LocationChoice.Worldwide, label: 'Worldwide' },
    { value: LocationChoice.Specific, label: 'Specific Countries' },
  ];

  const getLabel = (choice: { value: LocationChoice; label: string }): string => {
    if (choice.value === LocationChoice.Current && formState.currentCountry) {
      return `${getCountryByCode(formState.currentCountry)} Only`;
    }
    return choice.label;
  };

  return (
    <>
      <ListGroup className='event-filters_list'>
        {locationChoices.map((locationChoice) => (
          <ListGroup.Item
            key={locationChoice.value}
            action
            onClick={(event) => {
              event.preventDefault();
              setFormValue('location', locationChoice.value);
            }}
            className={formState.location === locationChoice.value ? 'selected' : ''}
          >
            <ToggleListItem
              label={getLabel(locationChoice)}
              isSelected={formState.location === locationChoice.value}
            ></ToggleListItem>
            {formState.location &&
              !!formState.countries?.length &&
              formState.location === LocationChoice.Specific &&
              locationChoice.value === LocationChoice.Specific && (
                <ul>
                  {formState.countries?.map((country) => (
                    <li key={country}>{getCountryByCode(country)}</li>
                  ))}
                </ul>
              )}
          </ListGroup.Item>
        ))}
      </ListGroup>
      {geocodeLookupError && formState.location && formState.location === LocationChoice.Current && (
        <>
          <p>We&apos;re having trouble finding the country from your location.</p>
          <CountrySelectField
            allowMultiple={false}
            label='Choose country'
            onChange={(result) => {
              if (result) {
                const country = result as AutocompleteResult;
                setFormValue('currentCountry', country.id);
              }
            }}
          />
        </>
      )}
      {formState.location && formState.location === LocationChoice.Specific && (
        <CountrySelectField
          label='Choose countries'
          currentValues={formState.countries}
          onChange={(results) => {
            if (results) {
              const countries = results as AutocompleteResult[];
              setFormValue(
                'countries',
                countries.map(({ id }) => id)
              );
            }
          }}
        />
      )}
    </>
  );
};
