import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import '../layouts.scss';

export const SigninLayout: FC = () => (
  <Container data-testid='layout-container' className='layout-container layout-container_sign-in'>
    <Outlet />
  </Container>
);
