import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import '../layouts.scss';

export const StartLayout: FC = () => (
  <Container data-testid='layout-container' className='layout-container_start'>
    <Outlet />
  </Container>
);
