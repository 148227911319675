import React, { FC, useState, useEffect } from 'react';
import { categoriesList, ChoiceOption } from '@bcx-tech/frontend-core';
import { DanceStyle, CompetitionPreference } from '@bcx-tech/tbc-types';
import { SingleChoiceField } from '@bcx-tech/weave';
import { BsTrash } from 'react-icons/bs';

import { useFormFields } from '../../hooks';

import './CompetitionPreferenceForm.scss';

const categoryChoices = categoriesList.map(({ key, value }) => ({ value: key, label: value }));

type CompetitionPreferenceFormState = Partial<CompetitionPreference>;

type CompetitionPreferenceFormProps = {
  id: string;
  danceStyle: DanceStyle;
  handleSubmit: (values: CompetitionPreference) => void;
  removePreference: (id: string) => void;
  competitionPreference?: CompetitionPreference;
};

export const CompetitionPreferenceForm: FC<CompetitionPreferenceFormProps> = ({
  id,
  danceStyle,
  handleSubmit,
  removePreference,
  competitionPreference,
}) => {
  const { formState, createChangeHandler } = useFormFields<CompetitionPreferenceFormState>({
    ...competitionPreference,
    danceStyle,
    id,
  });
  const [abilityLevels, setAbilityLevels] = useState<ChoiceOption[] | undefined>();

  useEffect(() => {
    if (formState.danceStyle && formState.category) {
      const category = categoriesList.find(({ key }) => key === formState.category);
      if (category && category.abilityLevels) {
        setAbilityLevels(category.abilityLevels);
      }
      handleSubmit(formState as CompetitionPreference);
    }
  }, [formState]);

  return (
    <div className='competition-preference-form'>
      <div>
        <SingleChoiceField
          choice={formState.category}
          type='select'
          onChoose={createChangeHandler('category')}
          choices={categoryChoices}
          chooseOptionLabel='Choose competition type'
        ></SingleChoiceField>
      </div>

      {formState && formState.category && abilityLevels && (
        <div>
          <SingleChoiceField
            choice={formState.abilityLevel}
            type='select'
            onChoose={createChangeHandler('abilityLevel')}
            choices={abilityLevels}
            chooseOptionLabel='Select level'
          ></SingleChoiceField>
        </div>
      )}
      <div className='competition-preference-form_remove-btn'>
        <BsTrash size={24} onClick={() => removePreference(id)} />
      </div>
    </div>
  );
};
