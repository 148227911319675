import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { RangeSliderField, SingleChoiceField } from '@bcx-tech/weave';
import { BsSearch } from 'react-icons/bs';
import { Form } from 'react-bootstrap';
import { useProfile } from '../../hooks';
import {
  setIsLookingForPartner,
  setIsWillingToRelocate,
  setMaxPreferredTravelDistance,
} from '../../features/dancerProfileSlice';

import { DancerProfileFormProps, DancerProfileFormState } from '../../interfaces';

import './ProfileForms.scss';

export const SeekPartnerForm: FC<DancerProfileFormProps> = ({
  validated,
  dancerProfile,
  onSubmit,
  formTitle,
  children,
}) => {
  const { formState, createChangeHandler } = useProfile({
    ...dancerProfile,
  });
  const dispatch = useDispatch();

  const yesNoChoices = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ];

  const submitHandler = (formState: DancerProfileFormState) => {
    if (formState.isLookingForPartner !== undefined) {
      dispatch(setIsLookingForPartner(formState.isLookingForPartner));
    }
    if (formState.isWillingToRelocate !== undefined) {
      dispatch(setIsWillingToRelocate(formState.isWillingToRelocate));
    }
    if (formState.maxPreferredTravelDistance) {
      dispatch(setMaxPreferredTravelDistance(formState.maxPreferredTravelDistance));
    }
  };

  const baseClass = 'profile-form';

  return (
    <Form
      className={baseClass}
      noValidate
      validated={validated}
      onSubmit={(e) => onSubmit(e, formState, submitHandler)}
    >
      <div className={`${baseClass}_container`}>
        <BsSearch size={48} className={`${baseClass}_form-icon`} />
        {formTitle && <h2 className={`${baseClass}_title`}>{formTitle}</h2>}
        <SingleChoiceField
          type='block-button'
          label={formTitle ? '' : 'Are you looking for a partner?'}
          choice={formState.isLookingForPartner !== undefined ? `${formState.isLookingForPartner}` : undefined}
          onChoose={createChangeHandler('isLookingForPartner')}
          choices={yesNoChoices}
          group='looking-for-partner'
          className='mb-4'
        />
        {formState.isLookingForPartner === true && (
          <>
            {formTitle && <h2 className={`${baseClass}_title`}>Are you willing to relocate?</h2>}
            <SingleChoiceField
              type='block-button'
              label={formTitle ? '' : 'Are you willing to relocate?'}
              choice={formState.isWillingToRelocate !== undefined ? `${formState.isWillingToRelocate}` : undefined}
              onChoose={createChangeHandler('isWillingToRelocate')}
              choices={yesNoChoices}
              group='relocate'
              className='mb-4'
            />
          </>
        )}
        {formState.isLookingForPartner === true && formState.isWillingToRelocate === false && (
          <>
            {formTitle && <h2 className={`${baseClass}_title`}>How far will you travel for training?</h2>}
            <RangeSliderField
              label={formTitle ? '' : 'How far will you travel for training?'}
              onChange={createChangeHandler('maxPreferredTravelDistance')}
              min={0}
              max={50}
              value={formState.maxPreferredTravelDistance}
              unitLabel={{ singular: 'kilometre', plural: 'kilometres' }}
              className='mb-4'
            />
          </>
        )}
      </div>
      {children}
    </Form>
  );
};
