import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { TiCamera } from 'react-icons/ti';
import { useProfile, useStorage } from '../../hooks';
import {
  setProfilePhoto,
  removeProfilePhoto,
  selectImageFolder,
  imageExtensions,
} from '../../features/dancerProfileSlice';

import { DancerProfileFormProps, DancerProfileFormState } from '../../interfaces';
import 'react-image-crop/src/ReactCrop.scss';
import './ProfileForms.scss';
import { ProfilePhotoUpload } from '../ProfilePhotoUpload';

export const ProfileImageForm: FC<DancerProfileFormProps> = ({
  validated,
  dancerProfile,
  onSubmit,
  formTitle,
  children,
}) => {
  const { formState, imagesPendingDeletion, handleProfilePhotoUpload, handleProfilePhotoRemoval } =
    useProfile(dancerProfile);
  const imageFolder = useSelector(selectImageFolder);
  const dispatch = useDispatch();
  const { deleteFile } = useStorage();

  const currentProfilePhoto = formState.profilePhoto;

  const submitHandler = async (formState: DancerProfileFormState) => {
    if (formState.profilePhoto) {
      dispatch(setProfilePhoto(formState.profilePhoto));
    } else {
      dispatch(removeProfilePhoto());
    }
    if (imagesPendingDeletion.length) {
      await Promise.all(imagesPendingDeletion.map(deleteFile));
    }
  };

  const baseClass = 'profile-form';

  return (
    <Form
      className={baseClass}
      noValidate
      validated={validated}
      onSubmit={(e) => onSubmit(e, formState, submitHandler)}
    >
      {!!imageFolder && (
        <div className={`${baseClass}_container`}>
          <TiCamera size={48} className={`${baseClass}_form-icon`} />
          {formTitle && <h2 className={`${baseClass}_title`}>{formTitle}</h2>}
          <ProfilePhotoUpload
            imageFolder={imageFolder}
            onChange={handleProfilePhotoUpload}
            onDelete={handleProfilePhotoRemoval}
            allowedExtensions={imageExtensions}
            currImage={currentProfilePhoto ? currentProfilePhoto : undefined}
          />
          <p className={`${baseClass}_text`}>You can change this later.</p>
        </div>
      )}
      {children}
    </Form>
  );
};
