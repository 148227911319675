import React, { FC, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchDancerProfileByUserId,
  selectDancerProfileStatus,
  selectDanceProfilePhotos,
  selectProfilePhoto,
  selectDanceProfileVideos,
} from '../../features/dancerProfileSlice';
import { LoadStatus } from '@bcx-tech/frontend-core';
import { ImageReference, VideoReference } from '@bcx-tech/tbc-types';
import { UserContext } from '../../contexts/UserContext';
import { ImageGrid, ImageGridItem } from '../../components/ImageGrid';
import { PreviewImages } from '../../components/PreviewImages';
import { PreviewVideo } from '../../components/PreviewVideo';

import './ProfileMediaView.scss';

export const ProfileMediaView: FC = () => {
  const dispatch = useDispatch();
  const loadStatus = useSelector(selectDancerProfileStatus);
  const profilePhoto = useSelector(selectProfilePhoto);
  const profileImages = useSelector(selectDanceProfilePhotos);
  const videos = useSelector(selectDanceProfileVideos);
  const { user } = useContext(UserContext);
  const [imageToPreview, setImageToPreview] = useState<ImageReference | undefined>();
  const [videoToPreview, setVideoToPreview] = useState<VideoReference | undefined>();
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showPreviewVideoModal, setShowPreviewVideoModal] = useState(false);

  const previewImage = (image: ImageReference) => {
    setImageToPreview(image);
    setShowPreviewModal(true);
  };

  const previewVideo = (video: VideoReference) => {
    setVideoToPreview(video);
    setShowPreviewVideoModal(true);
  };

  const hidePreviewModal = () => {
    setShowPreviewModal(false);
    setImageToPreview(undefined);
  };

  const hidePreviewVideoModal = () => {
    setShowPreviewVideoModal(false);
    setVideoToPreview(undefined);
  };

  useEffect(() => {
    if (user && loadStatus === LoadStatus.IDLE) {
      dispatch(fetchDancerProfileByUserId(user.uid));
    }
  }, [user, dispatch]);
  return (
    <div className='profile-media-view'>
      {(profilePhoto || !!profileImages.length) && (
        <div>
          <h2>Photos</h2>
          <ImageGrid>
            <ImageGridItem>
              {profilePhoto && (
                <img
                  onClick={() => previewImage(profilePhoto)}
                  src={profilePhoto?.transformedMediaURL}
                  alt='Profile photo'
                />
              )}
            </ImageGridItem>
            {profileImages.map((profileImage) => (
              <ImageGridItem key={profileImage.id}>
                <img onClick={() => previewImage(profileImage)} src={profileImage.transformedMediaURL} alt='' />
              </ImageGridItem>
            ))}
          </ImageGrid>
          {!!imageToPreview && (
            <PreviewImages
              images={profilePhoto ? [profilePhoto, ...profileImages] : profileImages}
              id={imageToPreview.id}
              handleOnHide={hidePreviewModal}
              showModal={showPreviewModal}
            />
          )}
        </div>
      )}
      {!!videos.length && (
        <div>
          <h2>Videos</h2>
          {videos.map((video) => (
            <div key={video.id} className='profile-media-view_video-container' onClick={() => previewVideo(video)}>
              <video width='100%'>
                <source src={video.transformedMediaURL} />
              </video>
            </div>
          ))}
          {!!videoToPreview && (
            <PreviewVideo
              handleOnHide={hidePreviewVideoModal}
              showModal={showPreviewVideoModal}
              video={videoToPreview}
            />
          )}
        </div>
      )}
    </div>
  );
};
