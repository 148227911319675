import React, { FC, createContext, useEffect, useState } from 'react';
import { User } from '@bcx-tech/tbc-types';
import { useFirebaseAuthState, useUserApi } from '@bcx-tech/frontend-core';

export const UserContext = createContext<{
  user?: User;
}>({});

export const UserProvider: FC = ({ children }) => {
  const [user, loading] = useFirebaseAuthState();
  const { getUser } = useUserApi();
  const [userRecord, setUserRecord] = useState<User>();

  useEffect(() => {
    async function getUserRecord(uid: string) {
      const record = await getUser(uid);
      setUserRecord(record);
    }
    if (!loading && user) {
      getUserRecord(user.uid);
    }

    return () => {
      setUserRecord(undefined);
    };
  }, [user, loading]);

  return <UserContext.Provider value={{ user: userRecord }}>{children}</UserContext.Provider>;
};
