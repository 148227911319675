import React, { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DanceStyle, CompetitionPreference } from '@bcx-tech/tbc-types';
import { PrimaryOutlineButton } from '../Buttons';
import { CompetitionPreferenceForm } from '../CompetitionPreferenceForm';

type CompetitionPreferencesFieldProps = {
  danceStyle?: DanceStyle;
  danceStyles?: DanceStyle[];
  handleChangeCompetitionPreference: (preference: CompetitionPreference) => void;
  handleRemoveCompetitionPreference: (preferenceId: string) => void;
  handleAddPreferenceForm: (preferences: Partial<CompetitionPreference>[]) => void;
  competitionPreferences?: CompetitionPreference[];
};

export const CompetitionPreferencesField: FC<CompetitionPreferencesFieldProps> = ({
  danceStyle,
  danceStyles = [],
  handleChangeCompetitionPreference,
  handleRemoveCompetitionPreference,
  handleAddPreferenceForm,
  competitionPreferences,
}) => {
  const showDanceStyleHeadings = !danceStyle;
  const addPreferenceForm = (danceStyle: DanceStyle) => {
    const initPreference = { danceStyle, id: uuidv4() };
    handleAddPreferenceForm(
      competitionPreferences?.length ? [...competitionPreferences, initPreference] : [initPreference]
    );
  };
  if (danceStyle) {
    danceStyles = [danceStyle];
  }

  return (
    <>
      {danceStyles &&
        danceStyles.map((danceStyle) => {
          const preferences = competitionPreferences
            ? competitionPreferences
                .filter((preference) => preference.danceStyle === danceStyle)
                .map((preference): { id: string; preference: CompetitionPreference } => ({
                  id: preference.id,
                  preference,
                }))
            : [];
          return (
            <div key={danceStyle}>
              {showDanceStyleHeadings && <p className='profile-form_subtitle'>{danceStyle} competition types</p>}
              {preferences.map(({ id, preference }) => (
                <CompetitionPreferenceForm
                  key={id}
                  id={id}
                  danceStyle={danceStyle}
                  competitionPreference={preference}
                  handleSubmit={handleChangeCompetitionPreference}
                  removePreference={handleRemoveCompetitionPreference}
                ></CompetitionPreferenceForm>
              ))}
              <div className='mt-4 d-flex justify-content-between profile-form_text'>
                {preferences.length ? 'Add another?' : 'Add competition'}
                <PrimaryOutlineButton label='+' handleClick={() => addPreferenceForm(danceStyle)} />
              </div>
            </div>
          );
        })}
    </>
  );
};
