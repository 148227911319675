import { danceProfileFeature } from '@bcx-tech/frontend-core';
import { ImageReference, VideoReference, DancerProfile } from '@bcx-tech/tbc-types';
import { RootState } from '../store';

const isEmpty = (obj: Record<string, unknown>): boolean => Object.keys(obj).length === 0;

const { slice } = danceProfileFeature;

export const {
  setFromUser,
  setUid,
  setFirstName,
  setLastName,
  setDateOfBirth,
  setGender,
  removeGender,
  setGenderIdentity,
  removeGenderIdentity,
  setHeight,
  setLocation,
  removeLocation,
  setImages,
  setProfilePhoto,
  removeProfilePhoto,
  setCoverVideo,
  removeCoverVideo,
  setVideos,
  setDanceRolePreferences,
  setDanceStyles,
  setIsOpenToTenDance,
  setCompetitionPreferences,
  setIsLookingForPartner,
  setIsWillingToRelocate,
  setMaxPreferredTravelDistance,
  setProfile,
  resetDancerProfile,
} = slice.actions;

export const selectDancerProfile = (state: RootState): DancerProfile | null =>
  !isEmpty(state.dancerProfile.dancerProfile) ? state.dancerProfile.dancerProfile : null;
export const selectInitDancerProfile = (state: RootState): DancerProfile => state.dancerProfile.dancerProfile;
export const selectDancerProfileStatus = (state: RootState) => state.dancerProfile.status;
export const selectDancerProfileState = (state: RootState) => state.dancerProfile;

export const selectDanceProfilePhotos = (state: RootState): ImageReference[] => {
  return state.dancerProfile.dancerProfile.images ?? [];
};

export const selectProfilePhoto = (state: RootState): ImageReference | undefined => {
  return state.dancerProfile.dancerProfile.profilePhoto;
};

export const selectDanceProfileVideos = (state: RootState): ImageReference[] => {
  return state.dancerProfile.dancerProfile.videos ?? [];
};

export const selectCoverVideo = (state: RootState): VideoReference | undefined => {
  return state.dancerProfile.dancerProfile.coverVideo;
};

export const selectImageFolder = (state: RootState): string | undefined => {
  return state.dancerProfile.dancerProfile.id ? `userdata/${state.dancerProfile.dancerProfile.id}/images` : undefined;
};

export const selectVideoFolder = (state: RootState): string | undefined => {
  return state.dancerProfile.dancerProfile.id ? `userdata/${state.dancerProfile.dancerProfile.id}/videos` : undefined;
};

export const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
export const videoExtensions = ['m4v', 'mp4', 'mov', 'MOV', 'M4V', 'MP4'];

export const { fetchDancerProfileByUserId, saveDancerProfile } = danceProfileFeature;

export default slice.reducer;
