import { useState } from 'react';
import {
  GeocodeResult,
  ImageReference,
  VideoReference,
  DancerProfile,
  DanceStyle,
  CompetitionPreference,
} from '@bcx-tech/tbc-types';
import { AutocompleteResult } from '@bcx-tech/weave';
import { useFormFields } from '../hooks';
import { DancerProfileFormState } from '../interfaces';

const maxVideos = 2;

export const useProfile = (dancerProfile: DancerProfile) => {
  const getRoleChoice = () => {
    if (!dancerProfile || !dancerProfile.danceRolePreferences || !dancerProfile.danceRolePreferences.length) {
      return undefined;
    }
    if (dancerProfile.danceRolePreferences.length === 2) {
      return 'BOTH';
    }
    const [preference] = dancerProfile.danceRolePreferences;
    return String(preference);
  };

  const { formState, setFormValue, removeFormValue, createChangeHandler } = useFormFields<DancerProfileFormState>({
    ...dancerProfile,
    genderChoice: dancerProfile && dancerProfile.genderIdentity ? 'CUSTOM' : dancerProfile?.gender,
    roleChoice: getRoleChoice(),
  });
  const [imageReferences, setImageReferences] = useState<ImageReference[]>(formState.images ?? []);
  const [videoReferences, setVideoReferences] = useState<VideoReference[]>(formState.videos ?? []);
  const [imagesPendingDeletion, setImagesPendingDeletion] = useState<ImageReference[]>([]);
  const [videosPendingDeletion, setVideosPendingDeletion] = useState<VideoReference[]>([]);

  const handleProfilePhotoUpload = (value: ImageReference | ImageReference[]) => {
    setFormValue('profilePhoto', value);
  };

  const handleProfilePhotoRemoval = (imageReference: ImageReference) => {
    setImagesPendingDeletion((prev) => [...prev, imageReference]);
    removeFormValue('profilePhoto');
  };

  const handleImagesUpload = (value: ImageReference | ImageReference[]) => {
    const iR = value as ImageReference;
    setImageReferences((prev) => [...prev, iR]);
  };

  const handleImagesRemoval = (imageReference: ImageReference) => {
    setImagesPendingDeletion((prev) => [...prev, imageReference]);
    const updated = imageReferences.filter((a) => a.fullPath !== imageReference.fullPath);
    setImageReferences(updated);
  };

  const handleVideosRemoval = (videoReference: VideoReference) => {
    setVideosPendingDeletion((prev) => [...prev, videoReference]);
    const updated = videoReferences.filter((a) => a.fullPath !== videoReference.fullPath);
    setVideoReferences(updated);
  };

  const handleCoverVideoUpload = (value: VideoReference) => {
    setFormValue('coverVideo', value);
  };

  const handleVideoUpload = (value: VideoReference) => {
    setVideoReferences((prev) => [...prev, value]);
  };

  const handleCoverVideoRemoval = (videoReference: VideoReference) => {
    setVideosPendingDeletion((prev) => [...prev, videoReference]);
    removeFormValue('coverVideo');
  };

  const isMaxVideos = () => videoReferences.length >= maxVideos;

  const handleSetLocation = (choice: AutocompleteResult | AutocompleteResult[]) => {
    if (choice) {
      const { data: location } = choice as AutocompleteResult<GeocodeResult>;
      setFormValue('location', location);
    } else {
      removeFormValue('location');
    }
  };

  const handleDanceStyleChoices = (values: string[]) => {
    setFormValue('danceStyles', values as DanceStyle[]);
  };

  const handleChangeCompetitionPreference = (preference: CompetitionPreference) => {
    if (!formState.competitionPreferences) {
      setFormValue('competitionPreferences', [preference]);
    } else {
      const updatedPreferences = [
        ...formState.competitionPreferences.filter(({ id }) => id !== preference.id),
        preference,
      ];
      setFormValue('competitionPreferences', updatedPreferences);
    }
  };

  const handleRemoveCompetitionPreference = (preferenceId: string) => {
    if (formState.competitionPreferences) {
      const updatedPreferences = formState.competitionPreferences.filter(({ id }) => id !== preferenceId);
      setFormValue('competitionPreferences', updatedPreferences);
    }
  };

  const handleAddPreferenceForm = (competitionPreferences: Partial<CompetitionPreference>[]) => {
    setFormValue('competitionPreferences', competitionPreferences as DancerProfileFormState['competitionPreferences']);
  };

  const handleGenderIdentity = (identity: { gender: string; custom?: string }) => {
    if (identity.gender === 'CUSTOM') {
      setFormValue('genderChoice', undefined);
      setFormValue('genderIdentity', identity.custom);
    } else {
      setFormValue('genderChoice', identity.gender);
      setFormValue('genderIdentity', undefined);
    }
  };

  return {
    formState,
    imageReferences,
    videoReferences,
    imagesPendingDeletion,
    videosPendingDeletion,
    isMaxVideos,
    createChangeHandler,
    handleSetLocation,
    handleProfilePhotoUpload,
    handleProfilePhotoRemoval,
    handleImagesUpload,
    handleCoverVideoUpload,
    handleCoverVideoRemoval,
    handleVideoUpload,
    handleVideosRemoval,
    handleImagesRemoval,
    handleDanceStyleChoices,
    handleChangeCompetitionPreference,
    handleRemoveCompetitionPreference,
    handleAddPreferenceForm,
    handleGenderIdentity,
  };
};
