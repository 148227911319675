import { useState, useEffect } from 'react';
import { BsGearFill, BsChevronLeft, BsCalendarHeart, BsPencil } from 'react-icons/bs';
import { NavBarLink } from '../interfaces';

const commonLinks: Record<string, NavBarLink> = {
  settings: { path: '/settings', ariaLabel: 'Settings', LinkIcon: BsGearFill },
  back: { path: '/', ariaLabel: 'Back to home screen', LinkIcon: BsChevronLeft },
  backToEvents: { path: '/events', ariaLabel: 'Back to events', LinkIcon: BsChevronLeft },
  favouriteEvents: { path: '/favourite-events', ariaLabel: 'Favourite events', LinkIcon: BsCalendarHeart },
  profile: { path: '/profile', ariaLabel: 'Profile', LinkIcon: BsChevronLeft },
  profileEdit: { path: '/profile/edit', ariaLabel: 'Edit profile', LinkIcon: BsChevronLeft },
  editProfileLink: { path: '/profile/edit', ariaLabel: 'Edit profile', LinkIcon: BsPencil },
};

type LayoutConfig = {
  title?: string;
  path?: string;
  pathRegex?: RegExp;
  linkLeft?: string;
  linkRight?: string;
  bgVariant?: string;
};

type LayoutProps = {
  title?: string;
  linkLeft?: NavBarLink;
  linkRight?: NavBarLink;
  bgVariant?: string;
};

export const useLayoutConfig = (configs: LayoutConfig[], path: string) => {
  const [pageData, setPageData] = useState<LayoutProps>({ title: '' });

  useEffect(() => {
    const config = configs.find(
      (config) => config.path === path || (config.pathRegex && path.match(config.pathRegex)?.input)
    );
    if (config) {
      const { title, linkLeft, linkRight, bgVariant } = config;
      const layoutProps: LayoutProps = {
        title,
        linkLeft: linkLeft ? commonLinks[linkLeft] : undefined,
        linkRight: linkRight ? commonLinks[linkRight] : undefined,
        bgVariant,
      };
      setPageData(layoutProps);
    }

    return () => {
      setPageData({ title: '' });
    };
  }, [path]);

  return { pageData };
};
