import React, { FC } from 'react';
import { getDateParts } from '@bcx-tech/frontend-core';

type DateViewProps = {
  startDate: number;
  endDate?: number;
};

export const DateView: FC<DateViewProps> = ({ startDate, endDate }) => {
  const { month, day, year } = getDateParts(startDate);
  let endDateParts;
  let endIsSameDay = false;
  let endIsSameMonth = false;
  let endIsSameYear = false;
  if (endDate) {
    endDateParts = getDateParts(endDate);
    endIsSameDay = day === endDateParts.day && month === endDateParts.month && year === endDateParts.year;
    endIsSameMonth = month === endDateParts.month && year === endDateParts.year;
    endIsSameYear = year === endDateParts.year;
  }

  let str;

  if (endDateParts) {
    str = `${day} ${month} ${year} - ${endDateParts.day} ${endDateParts.month} ${endDateParts.year}`;
    if (endIsSameDay) {
      str = `${day} ${month}`;
    } else if (endIsSameMonth) {
      str = `${day}-${endDateParts.day} ${month}`;
    } else if (endIsSameYear) {
      str = `${day} ${month} - ${endDateParts.day} ${endDateParts.month}`;
    }
  } else {
    str = `${day} ${month} ${year}`;
  }

  return (
    <div className='d-flex align-items-center'>
      <span>{str}</span>
    </div>
  );
};
