import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { VideoReference } from '@bcx-tech/tbc-types';
import { DeleteIconButton } from '../Buttons';

type PreviewVideoProps = {
  handleOnHide: () => void;
  showModal: boolean;
  handleDeleteVideo?: (image: VideoReference) => void;
  video: VideoReference;
};

export const PreviewVideo: FC<PreviewVideoProps> = ({ handleOnHide, showModal, handleDeleteVideo, video }) => {
  const onDelete = () => {
    if (handleDeleteVideo) {
      handleDeleteVideo(video);
    }
    handleOnHide();
  };

  return (
    <Modal show={showModal} fullscreen={true} onHide={handleOnHide} centered size='lg' className='preview-modal'>
      <Modal.Header closeButton closeVariant='white'></Modal.Header>
      <div className='h-100 d-flex flex-row align-items-center jusify-content-between'>
        <video autoPlay controls width='100%'>
          <source src={video.mediaURL} />
        </video>
      </div>
      <Modal.Footer>{!!handleDeleteVideo && <DeleteIconButton handleClick={onDelete} />}</Modal.Footer>
    </Modal>
  );
};
