import { LoadStatus, danceEventSearchFeature } from '@bcx-tech/frontend-core';
import { RootState } from '../store';

const { slice, entityAdapter } = danceEventSearchFeature;

const { selectById, selectAll } = entityAdapter.getSelectors((state: RootState) => state.danceEventSearch);

export const selectIsHomeScreenResultSet = (state: RootState) => {
  return state.danceEventSearch.homeScreenEventStatus === LoadStatus.SUCCEEDED;
};

export const selectHomeScreenEvent = (state: RootState) => {
  return state.danceEventSearch.homeScreenEventId
    ? selectById(state, state.danceEventSearch.homeScreenEventId)
    : undefined;
};

export const selectDanceEventSearchStatus = (state: RootState) => {
  return state.danceEventSearch.eventSearchStatus;
};

export const selectDanceEventFilters = (state: RootState) => {
  return state.danceEventSearch.danceEventFilters;
};

export const selectCurrentDateFrom = (state: RootState) => {
  return state.danceEventSearch.currentSearchTerms?.dateFrom;
};

export const selectCurrentDateTo = (state: RootState) => {
  return state.danceEventSearch.currentSearchTerms?.dateTo;
};

export const selectCurrentSearchResults = (state: RootState) => {
  return selectAll(state);
};

export const selectHasNextPage = (state: RootState) => {
  return !!state.danceEventSearch.nextPage;
};

export const selectLatestResult = (state: RootState) => {
  const results = selectAll(state);
  return results.pop();
};

export const selectChosenDate = (state: RootState) => {
  return new Date(state.danceEventSearch.currentChosenDate);
};

export const { setCurrentSearchTerms, resetEventSearch, setDateFrom, setDateTo, setChosenDate, resetChosenDateToNow } =
  slice.actions;

export const { fetchHomeScreenEvent, searchEvents } = danceEventSearchFeature;

export default slice.reducer;
