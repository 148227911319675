import React, { FC } from 'react';
import { Image } from 'react-bootstrap';
import { ImageReference } from '@bcx-tech/tbc-types';
import profileImageDefault from '../../assets/profile-image-default.png';

import './ProfilePhoto.scss';

type ProfilePhotoProps = {
  imageReference?: ImageReference;
  onClick?: () => void;
};

export const ProfilePhoto: FC<ProfilePhotoProps> = ({ imageReference, onClick }) => {
  return (
    <div className='profile-photo'>
      <Image
        onClick={onClick}
        src={imageReference?.transformedMediaURL || profileImageDefault}
        roundedCircle
        alt='Profile image'
      />
    </div>
  );
};
