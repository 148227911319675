import React, { FC, useEffect, useContext, Fragment } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  BsPersonFill,
  BsPencilFill,
  BsGlobeEuropeAfrica,
  BsCameraVideoFill,
  BsSearch,
  BsSliders,
} from 'react-icons/bs';
import { IoMdPhotos } from 'react-icons/io';
import { IconType } from 'react-icons';
import { LoadStatus } from '@bcx-tech/frontend-core';
import { DancerProfile, ImageReference } from '@bcx-tech/tbc-types';
import {
  fetchDancerProfileByUserId,
  selectInitDancerProfile,
  selectDancerProfileStatus,
  setProfilePhoto,
  removeProfilePhoto,
  saveDancerProfile,
  selectImageFolder,
  imageExtensions,
} from '../../features/dancerProfileSlice';
import { DancingFigureIcon } from '../../components/DancingFigureIcon';
import { ProfilePhotoUpload } from '../../components/ProfilePhotoUpload/ProfilePhotoUpload';
import { useStorage } from '../../hooks';

import { UserContext } from '../../contexts/UserContext';

import './ProfileEdit.scss';

type ProfileForm = {
  path: string;
  title: string;
  key: string;
  icon: IconType | FC;
  checkIsHidden?: (dancerProfile: DancerProfile) => boolean;
};

const profileForms: ProfileForm[] = [
  {
    path: '/profile/edit/personal-details',
    title: 'Personal Details',
    key: 'personal-details',
    icon: BsPersonFill,
  },
  {
    path: '/profile/edit/profile',
    title: 'Profile Text',
    key: 'bio',
    icon: BsPencilFill,
  },
  {
    path: '/profile/edit/location',
    title: 'Location',
    key: 'location',
    icon: BsGlobeEuropeAfrica,
  },
  {
    path: '/profile/edit/images',
    title: 'Images',
    key: 'images',
    icon: IoMdPhotos,
    checkIsHidden: (dancerProfile) => !dancerProfile.id,
  },
  {
    path: '/profile/edit/videos',
    title: 'Videos',
    key: 'videos',
    icon: BsCameraVideoFill,
    checkIsHidden: (dancerProfile) => !dancerProfile.id,
  },
  {
    path: '/profile/edit/dance-styles',
    title: 'Dance Styles',
    key: 'dance-styles',
    icon: DancingFigureIcon,
  },
  {
    path: '/profile/edit/competition-preferences',
    title: 'Competition Preferences',
    key: 'competition-preferences',
    icon: BsSliders,
    checkIsHidden: (dancerProfile) => !dancerProfile.danceStyles || !dancerProfile.danceStyles.length,
  },
  {
    path: '/profile/edit/partner-search',
    title: 'Dance Partner Preferences',
    key: 'partner-search',
    icon: BsSearch,
  },
];

export const ProfileEditScreen: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useContext(UserContext);
  const { deleteFile } = useStorage();
  const dancerProfile: DancerProfile | null = useSelector(selectInitDancerProfile);
  const imageFolder = useSelector(selectImageFolder);
  const loadStatus = useSelector(selectDancerProfileStatus);

  useEffect(() => {
    if (user && loadStatus === LoadStatus.IDLE) {
      dispatch(fetchDancerProfileByUserId(user.uid));
    }
  }, [user, dancerProfile, dispatch]);

  const changeProfilePhoto = (value: ImageReference | ImageReference[]) => {
    dispatch(setProfilePhoto(value));
    dispatch(saveDancerProfile());
  };

  const deleteProfilePhoto = async (imageReference: ImageReference) => {
    await deleteFile(imageReference);
    dispatch(removeProfilePhoto());
    dispatch(saveDancerProfile());
  };

  return (
    <div>
      {dancerProfile.uid && (
        <ListGroup>
          {!!imageFolder && (
            <ListGroup.Item>
              <div className='text-center ProfileContainerImage'>
                <ProfilePhotoUpload
                  imageFolder={imageFolder}
                  onChange={changeProfilePhoto}
                  onDelete={deleteProfilePhoto}
                  allowedExtensions={imageExtensions}
                  currImage={dancerProfile.profilePhoto ? dancerProfile.profilePhoto : undefined}
                />
              </div>
            </ListGroup.Item>
          )}
          {profileForms.map((profileForm) => {
            if (profileForm.checkIsHidden && profileForm.checkIsHidden(dancerProfile)) {
              return <Fragment key={profileForm.key} />;
            }
            return (
              <ListGroup.Item
                key={profileForm.key}
                action
                onClick={(event) => {
                  event.preventDefault();
                  navigate(profileForm.path);
                }}
                className='profile-edit_item'
              >
                {profileForm.icon && (
                  <span className='profile-edit_icon-container'>
                    <profileForm.icon size='36' className='profile-edit_icon-container_icon'></profileForm.icon>
                  </span>
                )}
                {profileForm.title}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      )}
    </div>
  );
};
