import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { FaVideo } from 'react-icons/fa';
import { VideoReference } from '@bcx-tech/tbc-types';
import { useStorage, useProfile } from '../../hooks';
import { removeCoverVideo, selectVideoFolder, setCoverVideo, videoExtensions } from '../../features/dancerProfileSlice';
import { NewVideoUploadField, VideoUploadMenu } from '../NewVideoUploadField';
import { DancerProfileFormProps, DancerProfileFormState } from '../../interfaces';

import './ProfileForms.scss';

export const CoverVideoForm: FC<DancerProfileFormProps> = ({
  validated,
  dancerProfile,
  onSubmit,
  formTitle,
  children,
}) => {
  const { uploadFile, deleteFile, getVideoMediaReference } = useStorage();
  const [showVideoUploadMenu, setShowVideoUploadMenu] = useState(false);
  const [contextVideo, setContextVideo] = useState<VideoReference>();
  const { formState, videosPendingDeletion, handleCoverVideoUpload, handleCoverVideoRemoval } =
    useProfile(dancerProfile);
  const dispatch = useDispatch();
  const videoFolder = useSelector(selectVideoFolder);

  const submitHandler = async (formState: DancerProfileFormState) => {
    if (formState.coverVideo) {
      dispatch(setCoverVideo(formState.coverVideo));
    } else {
      dispatch(removeCoverVideo());
    }
    if (videosPendingDeletion.length) {
      await Promise.all(videosPendingDeletion.map(deleteFile));
    }
  };

  const showContextMenu = (videoReference: VideoReference) => {
    setShowVideoUploadMenu(true);
    setContextVideo(videoReference);
  };

  const baseClass = 'profile-form';

  return (
    <Form
      className={baseClass}
      noValidate
      validated={validated}
      onSubmit={(e) => onSubmit(e, formState, submitHandler)}
    >
      {!!videoFolder && (
        <div className={`${baseClass}_container`}>
          <FaVideo size={48} className={`${baseClass}_form-icon`} />
          {formTitle && <h2 className={`${baseClass}_title`}>{formTitle}</h2>}
          {formState.coverVideo && (
            <div
              key={formState.coverVideo.id}
              className={`${baseClass}_video-container`}
              onClick={() => (formState.coverVideo ? showContextMenu(formState.coverVideo) : undefined)}
            >
              <video width='100%'>
                <source src={formState.coverVideo.transformedMediaURL} />
              </video>
            </div>
          )}
          {!formState.coverVideo && (
            <NewVideoUploadField
              uploadFile={(file) => uploadFile(videoFolder, file)}
              getVideoMediaReference={getVideoMediaReference}
              onChange={handleCoverVideoUpload}
              allowedExtensions={videoExtensions}
            />
          )}
        </div>
      )}
      {showVideoUploadMenu && contextVideo && (
        <VideoUploadMenu
          videoReference={contextVideo}
          handleClickUpload={handleCoverVideoRemoval}
          handleClickDelete={handleCoverVideoRemoval}
          showModal={showVideoUploadMenu}
          handleOnHide={() => setShowVideoUploadMenu(false)}
        />
      )}
      {!!formTitle && <p className={`${baseClass}_text`}>You can change this later.</p>}
      {children}
    </Form>
  );
};
