import React, { ComponentType, FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import { BsHouseDoorFill, BsPersonFill, BsChatLeftTextFill, BsCalendar3, BsSearch } from 'react-icons/bs';
import './footer.scss';
import { IconBaseProps } from 'react-icons';

type FooterItem = {
  link: string;
  title: string;
  ariaLabel: string;
  Icon: ComponentType<IconBaseProps>;
};

const items: FooterItem[] = [
  {
    link: '/',
    title: 'Home',
    ariaLabel: 'Home screen',
    Icon: BsHouseDoorFill,
  },
  {
    link: '/profile',
    title: 'Profile',
    ariaLabel: 'Profile screen',
    Icon: BsPersonFill,
  },
  {
    link: '/events',
    title: 'Calendar',
    ariaLabel: 'Competitions and dance events',
    Icon: BsCalendar3,
  },
  {
    link: '/search',
    title: 'Search',
    ariaLabel: 'Search',
    Icon: BsSearch,
  },
  {
    link: '/messages',
    title: 'Messages',
    ariaLabel: 'Messages',
    Icon: BsChatLeftTextFill,
  },
];

export const Footer: FC = () => {
  const location = useLocation();
  const showLabels = false;
  const classMap = ['d-flex', 'flex-column', 'justify-content-center', 'align-items-center'];
  return (
    <Navbar data-testid='footer' fixed='bottom' className={'footer d-flex justify-content-around'}>
      {items.map((item, idx) => {
        const isActive = item.link === location.pathname;
        return (
          <Link
            key={idx}
            to={item.link}
            aria-label={item.ariaLabel}
            className={(isActive ? [...classMap, 'active-route'] : classMap).join(' ')}
          >
            <item.Icon size={28} />
            {showLabels && <span>{item.title}</span>}
          </Link>
        );
      })}
    </Navbar>
  );
};
