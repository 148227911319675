import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { GenderIdentityField } from '@bcx-tech/weave';
import { Form } from 'react-bootstrap';
import { MdOutlinePermIdentity } from 'react-icons/md';
import { useProfile } from '../../hooks';
import { setGender, removeGender, setGenderIdentity, removeGenderIdentity } from '../../features/dancerProfileSlice';

import { DancerProfileFormProps, DancerProfileFormState } from '../../interfaces';

import './ProfileForms.scss';

export const GenderIdentityForm: FC<DancerProfileFormProps> = ({
  validated,
  dancerProfile,
  onSubmit,
  formTitle,
  children,
}) => {
  const { formState, handleGenderIdentity } = useProfile(dancerProfile);
  const dispatch = useDispatch();

  const submitHandler = (formState: DancerProfileFormState) => {
    if (formState.genderChoice) {
      dispatch(setGender(formState.genderChoice));
      dispatch(removeGenderIdentity());
    } else {
      dispatch(removeGender());
    }
    if (formState.genderIdentity) {
      dispatch(setGenderIdentity(formState.genderIdentity));
    }
  };

  const baseClass = 'profile-form';

  return (
    <Form
      className={baseClass}
      noValidate
      validated={validated}
      onSubmit={(e) => onSubmit(e, formState, submitHandler)}
    >
      <div className={`${baseClass}_container`}>
        <MdOutlinePermIdentity size={48} className={`${baseClass}_form-icon`} />
        {formTitle && <h2 className={`${baseClass}_title mb-2`}>{formTitle}</h2>}
        <GenderIdentityField
          choice={{
            gender: formState.genderIdentity ? 'CUSTOM' : formState.genderChoice ?? '',
            custom: formState.genderIdentity,
          }}
          onChoose={handleGenderIdentity}
        />
      </div>
      {children}
    </Form>
  );
};
