import React, { FC } from 'react';

export const DancingFigureIcon: FC<{ size?: number; className?: string }> = ({ size = 36, className }) => (
  <svg
    stroke='currentColor'
    fill='currentColor'
    strokeWidth='0'
    viewBox='0 0 26 26'
    width={size}
    height={size}
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path d='m 7.8325597,25.052197 c 0.56641,0 0.98633,-0.4297 0.98633,-0.9571 v -4.6777 l 2.7343703,-2.9101 c 0.04883,-0.0489 0.13672,-0.0489 0.18555,0.0097 l 2.61719,3.5059 2.22658,4.4238 c 0.6152,1.2402 2.3535,0.3516 1.7578,-0.8301 l -4.57032,-9.1601 c -0.41016,-0.8203 -0.61523,-1.9239 -0.71289,-3.0469 -0.0293,-0.36133 0.20508,-0.53711 0.51758,-0.38086 l 1.80664,0.91796 1.66989,3.3399 c 0.5957,1.1816 2.3828,0.3808 1.7481,-0.879 l -1.8164,-3.623 c -0.0977,-0.19532 -0.2442,-0.3418 -0.4395,-0.43946 L 13.94584,9.0463166 c -0.77148,-0.39063 -1.5625,-0.53711 -2.29492,-0.72266 -0.83984,-0.20508 -1.5918,-0.46875 -2.0996103,-1.19141 l -1.18164,-1.71875 v -3.378903 c 0,-0.527344 -0.44922,-0.966797 -0.986328,-0.966797 -0.537109,0 -0.976562,0.439453 -0.976562,0.966797 v 3.691403 c 0,0.21485 0.048828,0.36133 0.175781,0.54688 l 2.070309,2.98828 c 0.18555,0.27344 0.30274,0.44922 0.3418,0.9082004 0.07813,1.08394 0.3125,3.09574 0.75195,4.55074 l -2.695309,3.7403 c -0.126953,0.1953 -0.175781,0.3808 -0.175781,0.5664 v 5.0683 c 0,0.5274 0.419922,0.9571 0.95703,0.9571 z M 12.32475,7.2689666 c 1.17187,0 2.11914,-0.94726 2.11914,-2.13867 0,-1.17188 -0.94727,-2.11914 -2.11914,-2.11914 -1.19141,0 -2.13867,0.94726 -2.13867,2.11914 0,1.19141 0.94726,2.13867 2.13867,2.13867 z' />
  </svg>
);
