import React, { FC, useState, useEffect, FormEvent } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { User } from '@bcx-tech/tbc-types';
import { EmailField, TextField, SwitchField } from '@bcx-tech/weave';
import { useSignUpWithEmailAndPassword, useAnalytics, useRemoteConfig } from '@bcx-tech/frontend-core';
import { useFormFields } from '../../hooks';
import { PasswordField } from '../../components/PasswordField';
import { AlertMessage, AlertMessageProps } from '../../components/AlertMessage';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import { AnimatedLogo } from '../../components/Logo';
import { isValidPassword } from '../../lib/utils';

import './Signup.scss';

type FormState = {
  firstName?: string;
  lastName?: string;
  termsVersionAgreed?: string;
  isOptedIntoPromotionalNotifications?: boolean;
  tsandcsConsent?: boolean;
  email?: string;
  password?: string;
};

export const Signup: FC = () => {
  const { getConfigValue } = useRemoteConfig();
  const { logAnalyticsEvent } = useAnalytics();
  const navigate = useNavigate();
  const { formState, createChangeHandler } = useFormFields<FormState>({});
  const { createUserWithEmailAndPassword, newUser, loading, error } = useSignUpWithEmailAndPassword();
  const [alert, setAlert] = useState<AlertMessageProps & { isAlert: boolean }>({
    isAlert: false,
  });
  const enableSignup = getConfigValue('enableSignup');
  const isSignupEnabled = enableSignup.asBoolean();

  const springs = useSpring({
    from: { y: 200 },
    to: { y: 0 },
    delay: 2000,
  });

  const formContainerSprings = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 2500,
  });

  useEffect(() => {
    if (!loading) {
      if (newUser) {
        logAnalyticsEvent('sign_up', { method: 'Email' });
        navigate('/registration');
      }
      if (error) {
        setAlert({ isAlert: true, type: 'danger', error });
      }
    }

    if (!isSignupEnabled) {
      setAlert({
        isAlert: true,
        type: 'warning',
        message: 'Sorry, you are not able to sign up right now. Please check back later.',
      });
    }
  }, [isSignupEnabled, newUser, loading, error]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { firstName, lastName, email, password, isOptedIntoPromotionalNotifications, tsandcsConsent } = formState;
    if (firstName && lastName && email && password && tsandcsConsent) {
      const userRecord: Omit<User, 'id' | 'uid' | 'dateCreated' | 'dateUpdated'> = {
        firstName,
        lastName,
        email,
        isOptedIntoPromotionalNotifications: !!isOptedIntoPromotionalNotifications,
        termsVersionAgreed: 'v1.0.0',
        isEnabled: true,
        isDeleted: false,
      };
      createUserWithEmailAndPassword(email, password, userRecord);
    } else {
      setAlert({
        isAlert: true,
        type: 'danger',
        message: 'Please make sure you have completed all of the required fields in order to sign up.',
      });
    }
  };

  return (
    <div className='d-flex flex-column h-100 align-items-center justify-content-between'>
      <animated.div
        style={{
          ...springs,
        }}
      >
        <AnimatedLogo />
        <h1 className='signup_title'>Welcome to The Ballroom Connection</h1>
      </animated.div>
      {alert.isAlert && (
        <AlertMessage error={alert.error} message={alert.message} type={alert.type}>
          {!isSignupEnabled && <SecondaryButton label='Back' handleClick={() => navigate('/')} />}
        </AlertMessage>
      )}
      {isSignupEnabled && (
        <animated.div
          className='h-100'
          style={{
            ...formContainerSprings,
          }}
        >
          <Form className='signup_form' onSubmit={handleSubmit}>
            <div className='d-flex flex-column align-items-center justify-content-between h-100'>
              <div className='w-75'>
                <TextField placeholder='First name' onChangeText={createChangeHandler('firstName')} required />
                <TextField placeholder='Last name' onChangeText={createChangeHandler('lastName')} required />
                <EmailField showAddon={false} onChangeText={createChangeHandler('email')} />
                <PasswordField onChangeText={createChangeHandler('password')} validator={isValidPassword} />
                <SwitchField
                  label='Check if you would like to receive promotional messages from us'
                  onChoose={createChangeHandler('isOptedIntoPromotionalNotifications')}
                />
                <SwitchField
                  label='I agree to the terms and conditions'
                  required
                  onChoose={createChangeHandler('tsandcsConsent')}
                />
              </div>
              <div className='w-100 mt-3'>
                <PrimaryButton className='w-75 mb-3' loading={loading} type='submit' label='Create Account' />
                <SecondaryButton className='w-75 mb-4' label='Back' handleClick={() => navigate('/')} />
              </div>
            </div>
          </Form>
        </animated.div>
      )}
    </div>
  );
};
