import React, { FC } from 'react';
import { SwitchField, AutocompleteField, AutocompleteResult } from '@bcx-tech/weave';
import { Adjudicator, Coach, Promoter } from '@bcx-tech/tbc-types';
import { FiltersProps } from './@types/EventsFilters';
import { useLinkedEntity } from '../../hooks';

enum AutocompleteFieldName {
  Adjudicators = 'adjudicators',
  Coaches = 'coaches',
  Promoters = 'promoters',
}

export const AdditionalFilters: FC<FiltersProps> = ({ formState, setFormValue, createChangeHandler }) => {
  const adjudicatorsFirestoreClient = useLinkedEntity<Adjudicator>('judges-coaches');
  const coachesFirestoreClient = useLinkedEntity<Coach>('judges-coaches');
  const promotersFirestoreClient = useLinkedEntity<Promoter>('promoters');
  const entityMappers = {
    adjudicators: adjudicatorsFirestoreClient.mapToEntity,
    coaches: coachesFirestoreClient.mapToEntity,
    promoters: promotersFirestoreClient.mapToEntity,
  };

  const autocompleteMappers = {
    adjudicators: adjudicatorsFirestoreClient.mapToAutocomplete,
    coaches: coachesFirestoreClient.mapToAutocomplete,
    promoters: promotersFirestoreClient.mapToAutocomplete,
  };

  const mapValuesToAutocomplete = (values: (Promoter | Coach | Adjudicator)[], field: AutocompleteFieldName) =>
    values.map(autocompleteMappers[field]);

  const handleChangeAutocomplete = (values: AutocompleteResult[], field: AutocompleteFieldName) => {
    const entities = values.map(entityMappers[field]);
    setFormValue(field, entities);
  };

  const handleChangeAdjudicators = (values: AutocompleteResult | AutocompleteResult[]) => {
    if (values) {
      const v = values as AutocompleteResult[];
      handleChangeAutocomplete(v, AutocompleteFieldName.Adjudicators);
    }
  };

  const handleChangePromoters = (values: AutocompleteResult | AutocompleteResult[]) => {
    if (values) {
      const v = values as AutocompleteResult[];
      handleChangeAutocomplete(v, AutocompleteFieldName.Promoters);
    }
  };

  const handleChangeCoaches = (values: AutocompleteResult | AutocompleteResult[]) => {
    if (values) {
      const v = values as AutocompleteResult[];
      handleChangeAutocomplete(v, AutocompleteFieldName.Coaches);
    }
  };

  return (
    <div className='event-filters_additional'>
      <SwitchField
        label='Cash prizes'
        choice={formState.cashPrizes}
        onChoose={createChangeHandler('cashPrizes')}
        reverse={true}
      ></SwitchField>
      <SwitchField
        label='Show Dance rounds'
        choice={formState.showDanceEvents}
        onChoose={createChangeHandler('showDanceEvents')}
        reverse={true}
      ></SwitchField>
      <SwitchField
        label='Formation rounds'
        choice={formState.formationEvents}
        onChoose={createChangeHandler('formationEvents')}
        reverse={true}
      ></SwitchField>
      <SwitchField
        label='Specific adjudicators'
        choice={formState.specificAdjudicators}
        onChoose={createChangeHandler('specificAdjudicators')}
        reverse={true}
      ></SwitchField>
      {formState.specificAdjudicators && (
        <AutocompleteField
          label='Search for adjudicators'
          allowNew={false}
          fetchItems={adjudicatorsFirestoreClient.searchEntities}
          onChange={handleChangeAdjudicators}
          currentValues={
            formState.adjudicators
              ? mapValuesToAutocomplete(formState.adjudicators, AutocompleteFieldName.Adjudicators)
              : []
          }
          allowMultiple={true}
        />
      )}
      <SwitchField
        label='Specific promoters'
        choice={formState.specificPromoters}
        onChoose={createChangeHandler('specificPromoters')}
        reverse={true}
      ></SwitchField>
      {formState.specificPromoters && (
        <AutocompleteField
          label='Search for promoters'
          allowNew={false}
          fetchItems={promotersFirestoreClient.searchEntities}
          onChange={handleChangePromoters}
          currentValues={
            formState.promoters ? mapValuesToAutocomplete(formState.promoters, AutocompleteFieldName.Promoters) : []
          }
          allowMultiple={true}
        />
      )}
      <SwitchField
        label='Specific instructors'
        choice={formState.specificCoaches}
        onChoose={createChangeHandler('specificCoaches')}
        reverse={true}
      ></SwitchField>
      {formState.specificCoaches && (
        <AutocompleteField
          label='Search for coaches'
          allowNew={false}
          fetchItems={coachesFirestoreClient.searchEntities}
          onChange={handleChangeCoaches}
          currentValues={
            formState.coaches ? mapValuesToAutocomplete(formState.coaches, AutocompleteFieldName.Coaches) : []
          }
          allowMultiple={true}
        />
      )}
    </div>
  );
};
