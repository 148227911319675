import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { LongTextField } from '@bcx-tech/weave';
import { useProfile } from '../../hooks/useProfile';
import { setProfile } from '../../features/dancerProfileSlice';

import { DancerProfileFormProps, DancerProfileFormState } from '../../interfaces';

export const EditBioForm: FC<DancerProfileFormProps> = ({ dancerProfile, onSubmit, validated, children }) => {
  const { formState, createChangeHandler } = useProfile(dancerProfile);
  const dispatch = useDispatch();

  const submitHandler = (formState: DancerProfileFormState) => {
    if (formState.profile) {
      dispatch(setProfile(formState.profile));
    }
  };

  const baseClass = 'profile-form';

  return (
    <Form
      className={baseClass}
      noValidate
      validated={validated}
      onSubmit={(e) => onSubmit(e, formState, submitHandler)}
    >
      <LongTextField
        label='Say a few words about yourself'
        text={dancerProfile?.profile}
        onChangeText={createChangeHandler('profile')}
      />
      {children}
    </Form>
  );
};
