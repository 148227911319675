import React, { FC, useEffect } from 'react';
import { MultiChoiceField } from '@bcx-tech/weave';
import { DanceCategoryOption } from '@bcx-tech/tbc-types';
import { Card, ListGroup } from 'react-bootstrap';
import { useFormFields } from '../../hooks';
import { EventTypeFiltersProps } from './@types/EventsFilters';
import { ToggleListItem } from '../ToggleListItem';

type CompetitionCategoryFormState = {
  category: string;
  danceStyles?: string[];
  ageLevels?: string[];
};

type CompetitionCategoryFilterProps = {
  onChange: (categoryFilter: CompetitionCategoryFormState) => void;
  category: DanceCategoryOption;
  currentValues?: CompetitionCategoryFormState;
};

const CompetitionCategoryFilter: FC<CompetitionCategoryFilterProps> = ({ onChange, category, currentValues }) => {
  const { formState, createChangeHandler } = useFormFields<CompetitionCategoryFormState>({
    ...currentValues,
    category: category.key,
  });

  useEffect(() => {
    if (formState.danceStyles?.length || formState.ageLevels?.length) {
      onChange(formState);
    }
  }, [formState]);

  return (
    <Card className='event-filters_comp-cat'>
      <p>
        <strong>Only show events with these:</strong>
      </p>
      <MultiChoiceField
        label='Dance styles'
        type='checkbox'
        reverse
        options={category.danceStyles}
        choices={formState.danceStyles}
        onChoose={createChangeHandler('danceStyles')}
      ></MultiChoiceField>
      <MultiChoiceField
        label='Age categories'
        type='checkbox'
        reverse
        options={category.ageCategories}
        choices={formState.ageLevels}
        onChoose={createChangeHandler('ageLevels')}
      ></MultiChoiceField>
    </Card>
  );
};

export const EventTypeFilters: FC<EventTypeFiltersProps> = ({ categories, formState, setFormValue }) => {
  const handleEventType = (type: string) => {
    let value;
    if (!formState.eventTypes?.length) {
      value = [type];
    } else if (formState.eventTypes.includes(type)) {
      value = formState.eventTypes.filter((t) => t !== type);
    } else {
      value = [...formState.eventTypes, type];
    }
    setFormValue('eventTypes', value);
  };

  const isCategorySelected = (category: DanceCategoryOption) => {
    return !!formState.categories?.filter((c) => c.category === category.key).length;
  };

  const handleToggleCategory = (category: DanceCategoryOption) => {
    let value;
    if (!formState.categories?.length) {
      value = [{ category: category.key }];
    } else if (isCategorySelected(category)) {
      value = formState.categories.filter((c) => c.category !== category.key);
    } else {
      value = [...formState.categories, { category: category.key }];
    }
    setFormValue('categories', value);
  };

  const handleCategoryOptions = (categoryFormState: CompetitionCategoryFormState) => {
    const updated = formState.categories?.map((c) => {
      if (c.category === categoryFormState.category) {
        return { ...categoryFormState };
      }
      return { ...c };
    });
    setFormValue('categories', updated);
  };

  const getCategoryFormState = (category: DanceCategoryOption) => {
    return formState.categories?.find((c) => c.category === category.key);
  };

  return (
    <>
      <ListGroup className='event-filters_list'>
        <ListGroup.Item
          action
          onClick={(event) => {
            event.preventDefault();
            handleEventType('COMPETITION');
          }}
          className={formState.eventTypes?.includes('COMPETITION') ? 'selected' : ''}
        >
          <ToggleListItem
            label='Competitions'
            isSelected={formState.eventTypes?.includes('COMPETITION') || false}
          ></ToggleListItem>
        </ListGroup.Item>
        {formState.eventTypes?.includes('COMPETITION') && (
          <>
            <ListGroup className='event-filters_nested-list'>
              {categories.map((category) => {
                return (
                  <div key={category.key}>
                    <ListGroup.Item
                      action
                      onClick={(event) => {
                        event.preventDefault();
                        handleToggleCategory(category);
                      }}
                      className={isCategorySelected(category) ? 'selected' : ''}
                    >
                      <ToggleListItem label={category.value} isSelected={isCategorySelected(category)}></ToggleListItem>
                    </ListGroup.Item>
                    {isCategorySelected(category) && (
                      <CompetitionCategoryFilter
                        onChange={handleCategoryOptions}
                        category={category}
                        currentValues={getCategoryFormState(category)}
                      ></CompetitionCategoryFilter>
                    )}
                  </div>
                );
              })}
            </ListGroup>
          </>
        )}
        <ListGroup.Item
          action
          onClick={(event) => {
            event.preventDefault();
            handleEventType('TRAININGCAMP');
          }}
          className={formState.eventTypes?.includes('TRAININGCAMP') ? 'selected' : ''}
        >
          <ToggleListItem
            label='Training Camps/Masterclasses'
            isSelected={formState.eventTypes?.includes('TRAININGCAMP') || false}
          ></ToggleListItem>
        </ListGroup.Item>
      </ListGroup>
    </>
  );
};
