import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { danceRoleChoices, danceStyleChoices } from '@bcx-tech/frontend-core';
import { SingleChoiceField, MultiChoiceField, SwitchField } from '@bcx-tech/weave';
import { DanceStyle } from '@bcx-tech/tbc-types';
import { useProfile } from '../../hooks/useProfile';
import { setDanceRolePreferences, setDanceStyles, setIsOpenToTenDance } from '../../features/dancerProfileSlice';

import { DancerProfileFormProps, DancerProfileFormState } from '../../interfaces';

export const EditDanceStylesForm: FC<DancerProfileFormProps> = ({ dancerProfile, onSubmit, validated, children }) => {
  const { formState, createChangeHandler, handleDanceStyleChoices } = useProfile(dancerProfile);
  const dispatch = useDispatch();

  const submitHandler = (formState: DancerProfileFormState) => {
    if (formState.roleChoice) {
      dispatch(setDanceRolePreferences(formState.roleChoice));
    }
    if (formState.isOpenToTenDance !== undefined) {
      dispatch(setIsOpenToTenDance(formState.isOpenToTenDance));
    }
    if (formState.danceStyles) {
      dispatch(setDanceStyles(formState.danceStyles));
    }
  };

  const baseClass = 'profile-form';

  return (
    <Form
      className={baseClass}
      noValidate
      validated={validated}
      onSubmit={(e) => onSubmit(e, formState, submitHandler)}
    >
      <SingleChoiceField
        type='block-button'
        label='Preferred dance role:'
        choice={formState.roleChoice}
        onChoose={createChangeHandler('roleChoice')}
        choices={danceRoleChoices}
        className='stacked-block-button'
      />
      <MultiChoiceField
        type='block-button'
        label='Dance styles:'
        choices={formState.danceStyles}
        onChoose={handleDanceStyleChoices}
        options={danceStyleChoices}
        className='stacked-block-button'
      />
      {formState?.danceStyles?.includes(DanceStyle.Ballroom) && formState?.danceStyles?.includes(DanceStyle.Latin) && (
        <SwitchField
          label='Open to 10 Dance?'
          choice={formState.isOpenToTenDance}
          onChoose={createChangeHandler('isOpenToTenDance')}
          reverse={true}
        ></SwitchField>
      )}
      {children}
    </Form>
  );
};
