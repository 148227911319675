import React, { FC, useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { EmailField } from '@bcx-tech/weave';
import { useForgotAndResetPassword } from '@bcx-tech/frontend-core';
import { AlertMessage, AlertMessageProps } from '../../components/AlertMessage';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import { useFormFields } from '../../hooks';

import './ForgotPassword.scss';
import { Logo } from '../../components/Logo';

type FormState = {
  email?: string;
};

export const ForgotPassword: FC = () => {
  const navigate = useNavigate();
  const [alert, setAlert] = useState<AlertMessageProps & { isAlert: boolean }>({
    isAlert: false,
  });

  const { handleSendResetPasswordEmail } = useForgotAndResetPassword();
  const { formState, createChangeHandler } = useFormFields<FormState>({});

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email } = formState;
    if (email) {
      await handleSendResetPasswordEmail(email);
      setAlert({
        isAlert: true,
        type: 'info',
        message: `A link to reset your password has been sent to ${email}`,
      });
    }
  };

  return (
    <div className='d-flex flex-column h-100'>
      <div>
        <Logo />
        <h1 className='footer_title'>Forgotten your password?</h1>
      </div>
      {alert.isAlert && <AlertMessage error={alert.error} message={alert.message} type={alert.type}></AlertMessage>}
      <Form className='footer_form' onSubmit={handleSubmit}>
        <div className='d-flex flex-column align-items-center justify-content-between h-100'>
          <div>
            <p>Enter your email address to receive a link that will allow you to reset your password.</p>

            <EmailField showAddon={false} onChangeText={createChangeHandler('email')} />
          </div>
          <div className='w-75 d-flex flex-column align-items-center justify-content-between'>
            <PrimaryButton className='mb-1' type='submit' label='Reset password' />
            <SecondaryButton label='Back' handleClick={() => navigate('/')} />
          </div>
        </div>
      </Form>
    </div>
  );
};
