import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { ImageReference } from '@bcx-tech/tbc-types';
import { useStorage, useProfile } from '../../hooks';
import {
  setImages,
  selectImageFolder,
  setProfilePhoto,
  removeProfilePhoto,
  imageExtensions,
} from '../../features/dancerProfileSlice';
import { ProfilePhotoUpload } from '../ProfilePhotoUpload';
import { ImageUploadField, ImageUploadMenu } from '../ImageUploadField';
import { ImageGrid, ImageGridItem } from '../ImageGrid';

import { DancerProfileFormProps } from '../../interfaces';

import './ProfileForms.scss';

const maxImageUploads = 5;

const prepareImageUploadFields = (): number[] => {
  const arr = [];
  let idx = 0;
  while (idx < maxImageUploads) {
    arr.push(idx);
    idx += 1;
  }
  return arr;
};

export const ImagesForm: FC<DancerProfileFormProps> = ({ validated, dancerProfile, onSubmit, children }) => {
  const { uploadFile, deleteFile, getImageMediaReference } = useStorage();
  const [showImageUploadMenu, setShowImageUploadMenu] = useState(false);
  const [imageContext, setImageContext] = useState<ImageReference>();
  const {
    formState,
    handleProfilePhotoUpload,
    handleImagesUpload,
    handleImagesRemoval,
    handleProfilePhotoRemoval,
    imageReferences,
    imagesPendingDeletion,
  } = useProfile(dancerProfile);
  const imageFolder = useSelector(selectImageFolder);
  const dispatch = useDispatch();

  const submitHandler = async () => {
    if (imagesPendingDeletion.length) {
      await Promise.all(imagesPendingDeletion.map(deleteFile));
    }
    if (imageReferences) {
      dispatch(setImages(imageReferences));
    }
    if (formState.profilePhoto) {
      dispatch(setProfilePhoto(formState.profilePhoto));
    } else {
      dispatch(removeProfilePhoto());
    }
  };

  const showContextMenu = (imageReference: ImageReference) => {
    setShowImageUploadMenu(true);
    setImageContext(imageReference);
  };

  const handleHideContextMenu = () => {
    setShowImageUploadMenu(false);
    setImageContext(undefined);
  };

  const baseClass = 'profile-form';

  return (
    <Form
      className={baseClass}
      noValidate
      validated={validated}
      onSubmit={(e) => onSubmit(e, formState, submitHandler)}
    >
      {!!imageFolder && (
        <ImageGrid>
          <ImageGridItem>
            <ProfilePhotoUpload
              imageFolder={imageFolder}
              onChange={handleProfilePhotoUpload}
              onDelete={handleProfilePhotoRemoval}
              profilePhotoView={false}
              allowedExtensions={imageExtensions}
              currImage={formState.profilePhoto ? formState.profilePhoto : undefined}
            />
          </ImageGridItem>
          {prepareImageUploadFields().map((idx) => (
            <ImageGridItem key={idx}>
              {imageReferences && imageReferences[idx] && (
                <img src={imageReferences[idx].mediaURL} onClick={() => showContextMenu(imageReferences[idx])} />
              )}
              {!imageReferences ||
                (!imageReferences[idx] && (
                  <ImageUploadField
                    allowMultiple={false}
                    uploadFile={(file) => uploadFile(imageFolder, file)}
                    onChange={handleImagesUpload}
                    getImageMediaReference={getImageMediaReference}
                    allowedExtensions={imageExtensions}
                  />
                ))}
            </ImageGridItem>
          ))}
        </ImageGrid>
      )}
      {showImageUploadMenu && imageContext && (
        <ImageUploadMenu
          imageReference={imageContext}
          handleClickUpload={handleImagesRemoval}
          handleClickDelete={handleImagesRemoval}
          showModal={showImageUploadMenu}
          handleOnHide={handleHideContextMenu}
        />
      )}
      {children}
    </Form>
  );
};
