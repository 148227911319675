import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectEvent, fetchEvent } from '../../features/eventsSlice';
import { DanceEvent, Adjudicator, Coach, Promoter } from '@bcx-tech/tbc-types';
import { useLinkedEntity } from '../../hooks';
import { EventTile } from '../../components/EventTiles';
import { CompetitionView } from '../../components/CompetitionView';
import { TrainingCampView } from '../../components/TrainingCampView';

import { RootState } from '../../store';

type HOCProps = {
  event: DanceEvent;
};

const CompetitionViewHOC: FC<HOCProps> = ({ event }) => {
  const [adjudicatorCollection, setAdjudicatorCollection] = useState<Adjudicator[]>([]);
  const [promoterCollection, setPromoterCollection] = useState<Promoter[]>([]);
  const adjudicatorsFirestoreClient = useLinkedEntity<Adjudicator>('judges-coaches');
  const promotersFirestoreClient = useLinkedEntity<Promoter>('promoters');
  const { adjudicators, promoters } = event;

  useEffect(() => {
    async function getAdjudicators(ids: string[]) {
      const entities = await adjudicatorsFirestoreClient.fetchMultipleEntities(ids);
      setAdjudicatorCollection(entities);
    }
    async function getPromoters(ids: string[]) {
      const entities = await promotersFirestoreClient.fetchMultipleEntities(ids);
      setPromoterCollection(entities);
    }
    if (!adjudicatorCollection.length && adjudicators) {
      getAdjudicators(adjudicators);
    }
    if (!promoterCollection.length && promoters) {
      getPromoters(promoters);
    }
  }, [adjudicators, promoters]);

  return (
    <CompetitionView
      event={event}
      adjudicatorCollection={adjudicatorCollection}
      promoterCollection={promoterCollection}
    ></CompetitionView>
  );
};

const TrainingCampViewHOC: FC<HOCProps> = ({ event }) => {
  const [coachCollection, setCoachCollection] = useState<Coach[]>([]);
  const coachesFirestoreClient = useLinkedEntity<Coach>('judges-coaches');
  const { coaches } = event;

  useEffect(() => {
    async function getCoaches(ids: string[]) {
      const entities = await coachesFirestoreClient.fetchMultipleEntities(ids);
      setCoachCollection(entities);
    }
    if (!coachCollection.length && coaches) {
      getCoaches(coaches);
    }
  }, [coaches]);
  return <TrainingCampView event={event} coachCollection={coachCollection}></TrainingCampView>;
};

export const EventView: FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { eventId } = params;
  const event: DanceEvent | undefined = useSelector((state: RootState) => selectEvent(state, eventId || ''));

  useEffect(() => {
    if (!event && eventId) {
      dispatch(fetchEvent(eventId));
    }
  }, [eventId, dispatch]);

  return (
    <>
      {event && (
        <>
          <EventTile event={event} />
          {event.eventType === 'COMPETITION' && <CompetitionViewHOC event={event}></CompetitionViewHOC>}
          {event.eventType === 'TRAININGCAMP' && <TrainingCampViewHOC event={event}></TrainingCampViewHOC>}
        </>
      )}
    </>
  );
};
