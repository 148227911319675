import React, { FC, useState, useReducer, useEffect, Reducer, FormEvent } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { useAnalytics, useSignInWithEmailAndPassword } from '@bcx-tech/frontend-core';
import { EmailField } from '@bcx-tech/weave';
import { PasswordField } from '../../components/PasswordField';
import { Logo } from '../../components/Logo';
import { AlertMessage, AlertMessageProps } from '../../components/AlertMessage';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';

import './Login.scss';

type FormState = {
  email: string;
  password: string;
};

type Action = {
  type: string;
  value: string;
};

const reducer: Reducer<FormState, Action> = (prevState, action): FormState => {
  switch (action.type) {
    case 'email':
      return {
        ...prevState,
        email: action.value,
      };
    case 'password':
      return {
        ...prevState,
        password: action.value,
      };
  }
  return prevState;
};

export const Login: FC = () => {
  const { logAnalyticsEvent } = useAnalytics();
  const navigate = useNavigate();
  const [signInWithEmailAndPassword, user, loading, error] = useSignInWithEmailAndPassword();
  const [alert, setAlert] = useState<AlertMessageProps & { isAlert: boolean }>({
    isAlert: false,
  });

  useEffect(() => {
    if (user) {
      logAnalyticsEvent('login');
      navigate('/');
    }
    if (error) {
      setAlert({ isAlert: true, type: 'danger', error });
    }
  }, [user, error, navigate]);

  const [state, dispatch] = useReducer(reducer, {
    email: '',
    password: '',
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password } = state;
    signInWithEmailAndPassword(email, password);
  };

  return (
    <div className='d-flex flex-column h-100 align-items-center justify-content-between'>
      <div>
        <Logo />
        <h1 className='login_title'>Welcome back!</h1>
      </div>
      {alert.isAlert && <AlertMessage error={alert.error} message={alert.message} type={alert.type}></AlertMessage>}
      <Form className='login_form w-100' onSubmit={handleSubmit}>
        <div className='d-flex flex-column align-items-center justify-content-between h-100'>
          <div className='w-75'>
            <h2 className='login_subtitle'>Sign in</h2>
            <EmailField showAddon={false} onChangeText={(text: string) => dispatch({ type: 'email', value: text })} />
            <PasswordField onChangeText={(text: string) => dispatch({ type: 'password', value: text })} />
            <Link to={'/auth/forgot-password'} className='login_link'>
              Forgotten your password?
            </Link>
          </div>
          <div className='w-100 mt-3'>
            <PrimaryButton className='w-75 mb-3' loading={loading} type='submit' label='Log in' />
            <SecondaryButton className='w-75 mb-4' label='Back' handleClick={() => navigate('/')} />
          </div>
        </div>
      </Form>
    </div>
  );
};
