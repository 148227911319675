import { FC } from 'react';
import { danceStyleChoices } from '@bcx-tech/frontend-core';
import { DanceStyle } from '@bcx-tech/tbc-types';
import { DancerProfileFormState, DancerProfileFormProps } from '../../interfaces';
import {
  CoverVideoForm,
  DOBForm,
  GenderIdentityForm,
  HeightForm,
  ProfileImageForm,
  LocationForm,
  DanceRolesForm,
  DanceStylesForm,
  PreferencesForm,
  SeekPartnerForm,
  TenDanceForm,
} from '../../components/ProfileForms';

type FormConfig = {
  pageTitle?: string;
  formTitle?: string;
  RegistrationForm?: FC<DancerProfileFormProps>;
  submitButtonLabel?: string;
  context?: DancerProfileFormProps['context'];
  getPrev?: (formState?: DancerProfileFormState) => string;
  getNext?: (formState?: DancerProfileFormState) => string;
};

const getCompetitionFormKey = (value: string) => `${value.toLowerCase().replace(' ', '-')}-competitions`;

export class RegistrationFormController {
  getFormConfigs(): Record<string, FormConfig> {
    const compFormConfigs: Record<string, FormConfig> = {};

    for (let i = 0; i < danceStyleChoices.length; i++) {
      const { value, label } = danceStyleChoices[i];
      const key = getCompetitionFormKey(value);
      compFormConfigs[key] = {
        pageTitle: 'Dance Experience',
        formTitle: `In ${label} what types of competition do you enter?`,
        context: {
          danceStyle: value,
        },
        RegistrationForm: PreferencesForm,
        getPrev: (formState?: DancerProfileFormState) => {
          if (formState && formState.danceStyles && formState.danceStyles.length) {
            const i = formState.danceStyles.findIndex((s) => s === value);
            if (i === 0) {
              return formState?.danceStyles?.includes(DanceStyle.Ballroom) &&
                formState?.danceStyles?.includes(DanceStyle.Latin)
                ? 'tenDance'
                : 'styles';
            }
            const prev = formState.danceStyles[i - 1];
            return getCompetitionFormKey(prev);
          }
          return 'styles';
        },
        getNext: (formState?: DancerProfileFormState) => {
          if (formState && formState.danceStyles && formState.danceStyles.length) {
            const i = formState.danceStyles.findIndex((s) => s === value);
            if (i === formState.danceStyles.length - 1) {
              // end of the list, go to partner form
              return 'partner';
            }
            const next = formState.danceStyles[i + 1];
            return getCompetitionFormKey(next);
          }
          return 'partner';
        },
      };
    }

    return {
      intro: {
        getNext: () => 'dob',
      },
      dob: {
        pageTitle: 'Personal Details',
        formTitle: "What's your date of birth?",
        RegistrationForm: DOBForm,
        getNext: () => 'gender-identity',
      },
      'gender-identity': {
        pageTitle: 'Personal Details',
        formTitle: 'How do you identify?',
        RegistrationForm: GenderIdentityForm,
        getPrev: () => 'dob',
        getNext: () => 'height',
      },
      height: {
        pageTitle: 'Personal Details',
        formTitle: 'How tall are you?',
        RegistrationForm: HeightForm,
        getPrev: () => 'gender-identity',
        getNext: () => 'location',
      },
      location: {
        pageTitle: 'Personal Details',
        formTitle: 'Where do you live?',
        RegistrationForm: LocationForm,
        getPrev: () => 'height',
        getNext: () => 'profile-photo',
      },
      'profile-photo': {
        pageTitle: 'Personal Details',
        formTitle: 'Add a profile photo',
        RegistrationForm: ProfileImageForm,
        getPrev: () => 'location',
        getNext: () => 'video',
      },
      video: {
        pageTitle: 'Personal Details',
        formTitle: 'Show us your best moves with a video',
        RegistrationForm: CoverVideoForm,
        getPrev: () => 'profile-photo',
        getNext: () => 'roles',
      },
      roles: {
        pageTitle: 'Dance Experience',
        formTitle: 'What role do you dance?',
        RegistrationForm: DanceRolesForm,
        getPrev: () => 'video',
        getNext: () => 'styles',
      },
      styles: {
        pageTitle: 'Dance Experience',
        formTitle: 'What styles do you dance?',
        RegistrationForm: DanceStylesForm,
        getPrev: () => 'roles',
        getNext: (formState?: DancerProfileFormState) => {
          if (
            formState?.danceStyles?.includes(DanceStyle.Ballroom) &&
            formState?.danceStyles?.includes(DanceStyle.Latin)
          ) {
            return 'tenDance';
          }
          if (formState && formState.danceStyles && formState.danceStyles.length) {
            return getCompetitionFormKey(formState.danceStyles[0]);
          }
          return 'partner';
        },
      },
      tenDance: {
        pageTitle: 'Dance Experience',
        formTitle: 'You dance both Ballroom and Latin?',
        RegistrationForm: TenDanceForm,
        getPrev: () => 'styles',
        getNext: (formState?: DancerProfileFormState) => {
          if (formState && formState.danceStyles && formState.danceStyles.length) {
            return getCompetitionFormKey(formState.danceStyles[0]);
          }
          return 'partner';
        },
      },
      ...compFormConfigs,
      partner: {
        pageTitle: 'Dance Partner',
        formTitle: 'Are you looking for a partner?',
        RegistrationForm: SeekPartnerForm,
        submitButtonLabel: 'Complete Profile',
        getPrev: (formState?: DancerProfileFormState) => {
          if (formState && formState.danceStyles && formState.danceStyles.length) {
            return getCompetitionFormKey(formState.danceStyles[formState.danceStyles.length - 1]);
          }
          if (
            formState?.danceStyles?.includes(DanceStyle.Ballroom) &&
            formState?.danceStyles?.includes(DanceStyle.Latin)
          ) {
            return 'tenDance';
          }
          return 'styles';
        },
      },
    };
  }

  getFormProgress(stepId: string) {
    const totalSteps = Object.keys(this.getFormConfigs()).length;
    const currStep = Object.keys(this.getFormConfigs()).findIndex((i) => i === stepId);
    return Math.round((currStep / totalSteps) * 100);
  }
}
