import React, { FC } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useLayoutConfig } from '../../hooks/useLayoutConfig';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { AddFavourite } from '../../components/AddFavourite';
import { configs } from '../../layoutConfig';

import '../layouts.scss';

export const EventViewLayout: FC = () => {
  const location = useLocation();
  const params = useParams();
  const { pageData } = useLayoutConfig(configs, location.pathname);
  const classMap = ['layout-container', 'layout-container_event-view'];

  if (pageData.bgVariant) {
    classMap.push(`layout-container_event-view--${pageData.bgVariant}`);
  }
  return (
    <>
      <Header title={pageData.title} linkLeft={pageData?.linkLeft} bgVariant={pageData?.bgVariant}>
        <AddFavourite eventId={params?.eventId || ''} />
      </Header>
      <div className={classMap.join(' ')} data-testid='layout-container'>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};
