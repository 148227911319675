import React, { FC, useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { intervalToDuration } from 'date-fns';
import { Row, Col } from 'react-bootstrap';
import { BsSearch, BsPersonFill } from 'react-icons/bs';
import { FaRulerHorizontal } from 'react-icons/fa';
import { LoadStatus } from '@bcx-tech/frontend-core';
import { DancerProfile } from '@bcx-tech/tbc-types';
import {
  setFromUser,
  fetchDancerProfileByUserId,
  selectDancerProfile,
  selectDancerProfileStatus,
  selectProfilePhoto,
  selectCoverVideo,
} from '../../features/dancerProfileSlice';
import { LocationView } from '../../components/LocationView';
import { CompetitionPreferencesView } from '../../components/CompetitionPreferencesView';
import { AlertMessage } from '../../components/AlertMessage';
import { PrimaryButton } from '../../components/Buttons';
import { ProfilePhoto } from '../../components/ProfilePhoto';
import { PreviewVideo } from '../../components/PreviewVideo';
import { DancingFigureIcon } from '../../components/DancingFigureIcon';
import { UserContext } from '../../contexts/UserContext';

import coverImageDefault from '../../assets/profile-cover-image-default.png';
import './ProfileView.scss';

export const ProfileView: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dancerProfile: DancerProfile | null = useSelector(selectDancerProfile);
  const loadStatus = useSelector(selectDancerProfileStatus);
  const profilePhoto = useSelector(selectProfilePhoto);
  const coverVideo = useSelector(selectCoverVideo);
  const { user } = useContext(UserContext);
  const [age, setAge] = useState<number>();
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const handleCreateProfile = () => {
    dispatch(setFromUser(user));
    navigate('edit');
  };

  useEffect(() => {
    if (user && loadStatus === LoadStatus.IDLE) {
      dispatch(fetchDancerProfileByUserId(user.uid));
    }
    if (dancerProfile && dancerProfile.dateOfBirth) {
      const { years } = intervalToDuration({ start: dancerProfile.dateOfBirth, end: new Date() });
      setAge(years);
    }
  }, [user, dancerProfile, dispatch]);
  return (
    <>
      {loadStatus === LoadStatus.SUCCEEDED && !dancerProfile?.id && (
        <div className='text-center'>
          <AlertMessage message="You haven't created a profile yet">
            <PrimaryButton label='Create a profile' handleClick={handleCreateProfile} />
          </AlertMessage>
        </div>
      )}
      {loadStatus === LoadStatus.SUCCEEDED && dancerProfile?.id && (
        <div className='profile-view'>
          <div>
            {coverVideo && (
              <div
                onClick={() => setShowPreviewModal(true)}
                dangerouslySetInnerHTML={{
                  __html: `
                    <video
                      autoplay
                      loop
                      muted
                      playsinline
                      width='100%'
                      class='profile-view_cover-video'
                    >
                      <source src="${coverVideo.transformedMediaURL}" />
                    </video>`,
                }}
              />
            )}
            {!coverVideo && (
              <img className='profile-view_cover-image' src={coverImageDefault} alt='Profile cover image' />
            )}
          </div>
          <div className='d-flex justify-content-between'>
            <div className='profile-view_header-container'>
              <h1>
                <strong>
                  {dancerProfile.firstName}, {age}
                </strong>
              </h1>
              {dancerProfile.isOpenToTenDance && <span>10 Dance</span>}
            </div>
            <div className='profile-view_image-container'>
              <ProfilePhoto onClick={() => navigate('media')} imageReference={profilePhoto} />
            </div>
          </div>

          {dancerProfile.isLookingForPartner && (
            <Row className='profile-view_section profile-view_section_no-border'>
              <Col className='d-flex'>
                <BsSearch size={36} />
                <div className='ms-3'>
                  Looking for a partner
                  {dancerProfile.isWillingToRelocate && <span> (will relocate)</span>}
                </div>
              </Col>
            </Row>
          )}
          {dancerProfile.location && (
            <Row className='profile-view_section profile-view_section_no-border'>
              <Col>
                <LocationView location={dancerProfile.location}></LocationView>
              </Col>
            </Row>
          )}
          {dancerProfile.height && (
            <Row className='profile-view_section'>
              <Col className='d-flex'>
                <FaRulerHorizontal size={36} />
                <div className='ms-3'>{dancerProfile.height} cm</div>
              </Col>
            </Row>
          )}
          {dancerProfile.profile && (
            <Row className='profile-view_section'>
              <Col className='d-flex'>
                <BsPersonFill size={36} />
                <div className='ms-3'>
                  <p className='profile-view_section-heading'>About Me</p>
                  <p>{dancerProfile.profile}</p>
                </div>
              </Col>
            </Row>
          )}
          {!!dancerProfile.competitionPreferences?.length && (
            <Row className='profile-view_section'>
              <Col className='d-flex'>
                <DancingFigureIcon size={36} />
                <div className='ms-3'>
                  <p className='profile-view_section-heading'>Dance Styles</p>
                  <CompetitionPreferencesView
                    competitionPreferences={dancerProfile.competitionPreferences}
                    danceRolePreferences={dancerProfile.danceRolePreferences}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>
      )}
      {coverVideo && (
        <PreviewVideo handleOnHide={() => setShowPreviewModal(false)} showModal={showPreviewModal} video={coverVideo} />
      )}
    </>
  );
};
