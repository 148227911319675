import { useFunctions } from '@bcx-tech/frontend-core';
import { GeocodeResult } from '@bcx-tech/tbc-types';
import { AutocompleteResult } from '@bcx-tech/weave';

type ReverseGeocodeCallback = (result: GeocodeResult) => void;
type ReverseGeocodeErrorHandler = (err?: Error) => void;

export const useGeolocation = () => {
  const { httpsCallableFunction } = useFunctions();
  const getReverseGeocode = async (position: { lat: number; long: number }) => {
    const reverseGeocode = httpsCallableFunction('reverseGeocode');
    const result = await reverseGeocode(position);
    return result.data as GeocodeResult;
  };

  const lookupAddress = async (address: string): Promise<GeocodeResult[]> => {
    const geocode = httpsCallableFunction('geocode');
    const { data } = await geocode({ address });
    return data as GeocodeResult[];
  };

  const getAddressFromGeolocation = async (
    successHandler: ReverseGeocodeCallback,
    errorHandler?: ReverseGeocodeErrorHandler
  ): Promise<void> => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        try {
          const result = await getReverseGeocode({ lat: position.coords.latitude, long: position.coords.longitude });
          successHandler(result);
        } catch (err) {
          if (errorHandler) {
            errorHandler(err as Error);
          }
        }
      },
      () => {
        if (errorHandler) {
          errorHandler();
        }
      }
    );
  };

  const mapLocationToAutocomplete = (location: GeocodeResult): AutocompleteResult<GeocodeResult> => {
    return {
      id: 'loc-0',
      label: location.formattedAddress,
      data: location,
    };
  };

  const searchLocation = async (term: string) => {
    const results = await lookupAddress(term);
    return results.map((result, idx): AutocompleteResult<GeocodeResult> => {
      return {
        id: `${idx}`,
        label: result.formattedAddress,
        data: result,
      };
    });
  };

  return { getReverseGeocode, getAddressFromGeolocation, searchLocation, mapLocationToAutocomplete };
};
