import React, { FC } from 'react';
import { useSpring, animated } from '@react-spring/web';
import './Logo.scss';

import logo from '../../assets/new-bcx-logo.png';

export const Logo: FC = () => {
  return <img className='logo' src={logo} alt='The Ballroom Connection' />;
};

export const AnimatedLogo: FC = () => {
  const logoSprings = useSpring({
    from: { width: '100%' },
    to: { width: '30%' },
    delay: 2000,
  });

  return <animated.img className='logo' src={logo} alt='The Ballroom Connection' style={{ ...logoSprings }} />;
};
