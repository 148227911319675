import React, { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Loader } from '@bcx-tech/weave';
import { ImageReference } from '@bcx-tech/tbc-types';
import { DeleteIconButton } from '../Buttons';

type PreviewImageProps = {
  handleOnHide: () => void;
  showModal: boolean;
  handleDeleteImage?: (image: ImageReference) => void;
  image: ImageReference;
};

export const PreviewImage: FC<PreviewImageProps> = ({ handleOnHide, showModal, handleDeleteImage, image }) => {
  const [isLoading, setIsLoading] = useState(true);
  const onDelete = () => {
    if (handleDeleteImage) {
      handleDeleteImage(image);
    }
    handleOnHide();
  };

  const onImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <Modal
      id='preview-modal'
      show={showModal}
      fullscreen={true}
      onHide={handleOnHide}
      centered
      size='lg'
      className='preview-modal'
    >
      <Modal.Header closeButton closeVariant='white'></Modal.Header>
      <div className='h-100 d-flex flex-row align-items-center jusify-content-between'>
        {isLoading && (
          <div className='w-100 d-flex flex-column justify-content-center'>
            <Loader />
          </div>
        )}
        <img className='img-fluid w-100' src={image.mediaURL} alt={image.name} onLoad={onImageLoad} />
      </div>
      <Modal.Footer>{!!handleDeleteImage && <DeleteIconButton handleClick={onDelete} />}</Modal.Footer>
    </Modal>
  );
};
