import React, { FC, ComponentType } from 'react';
import { Button } from 'react-bootstrap';
import { IconBaseProps } from 'react-icons';
import { BsTrash } from 'react-icons/bs';

import './Buttons.scss';

type BaseButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  handleClick?: () => void;
  href?: string;
  className?: string;
};

type ButtonProps = BaseButtonProps & {
  loading?: boolean;
  loadingLabel?: string;
  label: string;
  ButtonIcon?: ComponentType<IconBaseProps>;
};

const iconSize = 24;

export const PrimaryButton: FC<ButtonProps> = ({
  loading = false,
  disabled = false,
  handleClick,
  loadingLabel = 'Loading',
  label,
  type = 'button',
  href,
  ButtonIcon,
  className,
}) => {
  return (
    <Button
      className={`btn-base bt-base_primary ${className}`}
      variant='primary'
      disabled={disabled || !!loading}
      type={type}
      href={href}
      onClick={!loading && handleClick ? handleClick : undefined}
    >
      {ButtonIcon && (
        <span className='btn-base_icon'>
          <ButtonIcon size={iconSize} />
        </span>
      )}
      {loading ? loadingLabel : label}
    </Button>
  );
};

export const PrimaryOutlineButton: FC<ButtonProps> = ({
  loading = false,
  disabled = false,
  handleClick,
  loadingLabel = 'Loading',
  label,
  type = 'button',
  href,
  ButtonIcon,
  className,
}) => {
  return (
    <Button
      className={`btn-base bt-base_primary-outline ${className}`}
      variant='outline-primary'
      disabled={disabled || !!loading}
      type={type}
      href={href}
      onClick={!loading && handleClick ? handleClick : undefined}
    >
      {ButtonIcon && (
        <span className='btn-base_icon'>
          <ButtonIcon size={iconSize} />
        </span>
      )}
      {loading ? loadingLabel : label}
    </Button>
  );
};

export const SecondaryButton: FC<ButtonProps> = ({
  loading = false,
  handleClick,
  loadingLabel = 'Loading',
  label,
  type = 'button',
  href,
  ButtonIcon,
  className,
}) => {
  return (
    <Button
      className={`btn-base bt-base_secondary ${className}`}
      variant='secondary'
      disabled={!!loading}
      type={type}
      href={href}
      target='_blank'
      onClick={!loading && handleClick ? handleClick : undefined}
    >
      {ButtonIcon && (
        <span className='btn-base_icon'>
          <ButtonIcon size={iconSize} />
        </span>
      )}
      {loading ? loadingLabel : label}
    </Button>
  );
};

export const DeleteButton: FC<ButtonProps> = ({
  loading = false,
  handleClick,
  loadingLabel = 'Loading',
  label,
  type = 'button',
  href,
  ButtonIcon,
  className,
}) => {
  return (
    <Button
      className={`btn-base bt-base_delete ${className}`}
      variant='danger'
      size='sm'
      disabled={!!loading}
      type={type}
      href={href}
      target='_blank'
      onClick={!loading && handleClick ? handleClick : undefined}
    >
      {ButtonIcon && (
        <span className='btn-base_icon'>
          <ButtonIcon size={iconSize} />
        </span>
      )}
      {loading ? loadingLabel : label}
    </Button>
  );
};

export const DeleteIconButton: FC<BaseButtonProps> = ({
  disabled = false,
  handleClick,
  type = 'button',
  href,
  className,
}) => {
  return (
    <Button
      className={`btn-base btn-base_delete ${className}`}
      variant='danger'
      disabled={disabled}
      type={type}
      href={href}
      onClick={handleClick ? handleClick : undefined}
    >
      <BsTrash size={iconSize} />
    </Button>
  );
};
