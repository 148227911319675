import React, { FC, useState } from 'react';
import { Modal, ListGroup, ListGroupItem } from 'react-bootstrap';
import { BsImage, BsTrash } from 'react-icons/bs';
import { TbCameraPlus } from 'react-icons/tb';
import { ImageReference } from '@bcx-tech/tbc-types';
import { PreviewImage } from '../PreviewImage';

import './ImageUploadMenu.scss';

type ImageUploadMenuProps = {
  imageReference: ImageReference;
  handleOnHide: () => void;
  showModal: boolean;
  handleClickUpload: (imageReference: ImageReference) => void;
  handleClickDelete: (imageReference: ImageReference) => void | Promise<void>;
};

export const ImageUploadMenu: FC<ImageUploadMenuProps> = ({
  imageReference,
  handleOnHide,
  showModal,
  handleClickUpload,
  handleClickDelete,
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const onClickUpload = async () => {
    await handleClickUpload(imageReference);
    handleOnHide();
  };

  const onClickDelete = async () => {
    await handleClickDelete(imageReference);
    handleOnHide();
  };

  const onClickPreview = async () => {
    setShowPreview(true);
  };

  return (
    <>
      <Modal id='image-upload-menu' show={showModal} onHide={handleOnHide} size='lg' className='image-upload-menu'>
        <div className='h-100 d-flex flex-column align-items-center'>
          <ListGroup className='w-100'>
            <ListGroupItem action onClick={onClickPreview}>
              <BsImage size={20} />
              <span className='image-upload-menu_label'>View photo</span>
            </ListGroupItem>
            <ListGroupItem action onClick={onClickUpload}>
              <TbCameraPlus size={20} />
              <span className='image-upload-menu_label'>Choose a new photo</span>
            </ListGroupItem>
            <ListGroupItem action onClick={onClickDelete} id='image-upload-menu_trash'>
              <BsTrash size={20} />
              <span className='image-upload-menu_label'>Delete photo</span>
            </ListGroupItem>
            <ListGroupItem action onClick={handleOnHide} className='text-center'>
              <span className='image-upload-menu_label'>Cancel</span>
            </ListGroupItem>
          </ListGroup>
        </div>
      </Modal>
      <PreviewImage showModal={showPreview} handleOnHide={() => setShowPreview(false)} image={imageReference} />
    </>
  );
};
