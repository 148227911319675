import { useStorage as useCommonStorage } from '@bcx-tech/frontend-core';
import { CDNMediaReference } from '@bcx-tech/tbc-types';
import { Crop } from 'react-image-crop';

export const useStorage = () => {
  const { getMediaCdnUrl, uploadFile, deleteFile } = useCommonStorage();

  const getImageMediaReference = (url: string, cropRegion?: Crop): CDNMediaReference => {
    const mediaURL = getMediaCdnUrl(url);
    const transformedUrl = new URL(mediaURL);
    if (cropRegion) {
      const { width: w, height: h, x, y } = cropRegion;
      transformedUrl.searchParams.set('tr', `cm-extract,w-${w},h-${h},x-${x},y-${y}`);
    }
    return { mediaURL, transformedMediaURL: transformedUrl.href };
  };

  const getVideoMediaReference = (url: string): CDNMediaReference => {
    const mediaURL = getMediaCdnUrl(url);
    const transformedUrl = new URL(mediaURL);
    transformedUrl.searchParams.set('tr', `w-400,h-250,cm-pad_resize,bg-blurred,q-90`);
    return { mediaURL, transformedMediaURL: transformedUrl.href };
  };

  return { uploadFile, deleteFile, getImageMediaReference, getVideoMediaReference };
};
