import React, { FC } from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Header.scss';
import { NavBarLink } from '../../interfaces';

type HeaderProps = {
  title?: string;
  linkLeft?: NavBarLink;
  linkRight?: NavBarLink;
  className?: string;
  bgVariant?: string;
};

const iconSize = 20;

const prepareLink = (link: NavBarLink, placement: 'left' | 'right') => {
  return (
    <Link data-testid={`header-link-${placement}`} to={link.path} aria-label={link.ariaLabel}>
      {link.LinkIcon && <link.LinkIcon size={iconSize} />}
      {link.text}
    </Link>
  );
};

export const Header: FC<HeaderProps> = ({ title, linkLeft, linkRight, className, bgVariant, children }) => {
  const classMap = ['header', className];
  if (bgVariant) {
    classMap.push(`header--${bgVariant}`);
  }
  return (
    <Navbar data-testid='header' expand={'lg'} className={classMap.join(' ')} fixed='top'>
      <div className='header_link'>{linkLeft && prepareLink(linkLeft, 'left')}</div>
      {title && (
        <div className='header_title'>
          <h1>{title}</h1>
        </div>
      )}
      <div className='header_link'>{children || (linkRight ? prepareLink(linkRight, 'right') : undefined)}</div>
    </Navbar>
  );
};
