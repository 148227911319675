import React, { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { categoriesList } from '@bcx-tech/frontend-core';
import { CompetitionPreference, DancePreference } from '@bcx-tech/tbc-types';
import './CompetitionPreferencesView.scss';

type CompetitionPreferencesViewProps = {
  competitionPreferences: CompetitionPreference[];
  danceRolePreferences?: DancePreference[];
};

export const CompetitionPreferencesView: FC<CompetitionPreferencesViewProps> = ({
  competitionPreferences,
  danceRolePreferences,
}) => {
  const danceStyles = Array.from(new Set(competitionPreferences.map(({ danceStyle }) => danceStyle)));

  const getCategoryValue = (key: string) => {
    const c = categoriesList.find((cat) => cat.key === key);
    return c?.value;
  };

  const getAbilityLevel = (categoryKey: string, abilityKey: string) => {
    const c = categoriesList.find((cat) => cat.key === categoryKey);
    if (!c || !c.abilityLevels) {
      return null;
    }
    const a = c?.abilityLevels.find((ability) => ability.value === abilityKey);
    return a?.label;
  };

  let danceRolePreference: string;

  if (danceRolePreferences) {
    if (danceRolePreferences.length > 1) {
      danceRolePreference = `${DancePreference.Leader}/${DancePreference.Follower}`;
    } else {
      danceRolePreference = `${danceRolePreferences[0]}`;
    }
  }
  const groups = danceStyles.map((danceStyle) => {
    return {
      danceStyle,
      preferences: competitionPreferences.filter((preference) => preference.danceStyle === danceStyle),
    };
  });

  return (
    <Container>
      {groups.map((group) => (
        <Row key={group.danceStyle}>
          <Col>
            <div className='competition-preferences-view_dance-style'>{group.danceStyle}</div>
            {danceRolePreference && <div>({danceRolePreference})</div>}
          </Col>
          <Col>
            {group.preferences.map((pref) => (
              <div className='mb-3' key={pref.id}>
                <div>
                  <strong>{getCategoryValue(pref.category)}</strong>
                </div>
                {pref.abilityLevel && <div>{getAbilityLevel(pref.category, pref.abilityLevel)}</div>}
              </div>
            ))}
          </Col>
        </Row>
      ))}
    </Container>
  );
};
