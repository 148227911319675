import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { FaRulerHorizontal } from 'react-icons/fa';
import { SingleChoiceField } from '@bcx-tech/weave';
import { Form } from 'react-bootstrap';
import { useProfile } from '../../hooks';
import { setHeight } from '../../features/dancerProfileSlice';
import { prepareHeightRange } from '../../lib/utils';

import { DancerProfileFormProps, DancerProfileFormState } from '../../interfaces';

import './ProfileForms.scss';

export const HeightForm: FC<DancerProfileFormProps> = ({ validated, dancerProfile, onSubmit, formTitle, children }) => {
  const { createChangeHandler, formState } = useProfile(dancerProfile);
  const dispatch = useDispatch();

  const submitHandler = (formState: DancerProfileFormState) => {
    if (formState.height) {
      dispatch(setHeight(formState.height));
    }
  };

  const heightChoices = prepareHeightRange();

  const baseClass = 'profile-form';

  return (
    <Form
      className={baseClass}
      noValidate
      validated={validated}
      onSubmit={(e) => onSubmit(e, formState, submitHandler)}
    >
      <div className={`${baseClass}_container`}>
        <FaRulerHorizontal size={48} className={`${baseClass}_form-icon`} />
        {formTitle && <h2 className={`${baseClass}_title`}>{formTitle}</h2>}
        <SingleChoiceField
          label='(without heels)'
          type='select'
          choice={`${dancerProfile?.height}`}
          choices={heightChoices}
          onChoose={createChangeHandler('height')}
        />
      </div>
      {children}
    </Form>
  );
};
