import { favouriteEventsFeature } from '@bcx-tech/frontend-core';
import { RootState } from '../store';

const { slice } = favouriteEventsFeature;

export const selectFavouriteEvents = (state: RootState) => {
  return state.favouriteEvents.favouriteEventIds;
};

export const { setUserId } = slice.actions;

export const { fetchFavouriteEventIds, addEventIdToFavourites, removeEventIdFromFavourites } = favouriteEventsFeature;

export default slice.reducer;
