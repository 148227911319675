import React, { FC, FormEvent } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { categoriesList } from '@bcx-tech/frontend-core';
import { DanceEventFilters } from '@bcx-tech/tbc-types';
import { BsGlobeAmericas, BsSliders } from 'react-icons/bs';
import { DancingFigureIcon } from '../DancingFigureIcon';
import { useFormFields } from '../../hooks';
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { FormState } from './@types/EventsFilters';
import './EventsFilters.scss';

import { LocationFilters } from './LocationFilters';
import { EventTypeFilters } from './EventTypeFilters';
import { AdditionalFilters } from './AdditionalFilters';

type EventsFiltersProps = {
  handleSubmit: (eventFilters: DanceEventFilters) => void;
  handleReset: () => void;
  savedEventFilters?: DanceEventFilters;
};

export const EventsFilters: FC<EventsFiltersProps> = ({ handleSubmit, handleReset, savedEventFilters }) => {
  const initialFormState: FormState = savedEventFilters
    ? {
        ...savedEventFilters,
      }
    : { id: uuidv4() };
  const { formState, resetFormState, setFormValue, createChangeHandler } = useFormFields<FormState>(initialFormState);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const eventFilters: DanceEventFilters = {
      ...formState,
    };
    handleSubmit(eventFilters);
  };

  const onReset = () => {
    resetFormState({ id: uuidv4() });
    handleReset();
  };

  return (
    <Form noValidate onSubmit={onSubmit} onReset={onReset}>
      <Accordion alwaysOpen>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>
            <BsGlobeAmericas size={36} className='event-filters_header-icon' /> Location
          </Accordion.Header>
          <Accordion.Body>
            <LocationFilters
              formState={formState}
              setFormValue={setFormValue}
              createChangeHandler={createChangeHandler}
            ></LocationFilters>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='1'>
          <Accordion.Header>
            <DancingFigureIcon size={36} className='event-filters_header-icon' /> Type of Event
          </Accordion.Header>
          <Accordion.Body>
            <EventTypeFilters
              categories={categoriesList}
              formState={formState}
              setFormValue={setFormValue}
              createChangeHandler={createChangeHandler}
            ></EventTypeFilters>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='2'>
          <Accordion.Header>
            <BsSliders size={36} className='event-filters_header-icon' /> Additional Filters
          </Accordion.Header>
          <Accordion.Body>
            <AdditionalFilters
              formState={formState}
              setFormValue={setFormValue}
              createChangeHandler={createChangeHandler}
            ></AdditionalFilters>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className='event-filters_actions'>
        <PrimaryButton className='w-100 mb-3' type='submit' label='Apply filters'></PrimaryButton>
        <SecondaryButton
          className='w-100 mb-4'
          type='button'
          handleClick={onReset}
          label='Clear filters'
        ></SecondaryButton>
      </div>
    </Form>
  );
};
