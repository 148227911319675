import React, { FC } from 'react';
import { getDateParts, titleCase } from '@bcx-tech/frontend-core';
import { DanceEvent } from '@bcx-tech/tbc-types';
import { EventTile } from './EventTile';
import './EventTiles.scss';

type EventTilesProps = {
  events: DanceEvent[];
  hideDate?: boolean;
};

type EventDateGroup = {
  date: number;
  formattedDate: string;
  events: DanceEvent[];
};

type EventGroup = {
  monthAndYear: string;
  formattedDate: string;
  eventsGroup: EventDateGroup[];
};

export const EventTiles: FC<EventTilesProps> = ({ events, hideDate = false }) => {
  const eventDateGroups: EventDateGroup[] = [];
  const eventsByMonth: EventGroup[] = [];

  events.forEach((event) => {
    const i = eventDateGroups.findIndex((g) => g.date === event.startDate);
    if (i > -1) {
      eventDateGroups[i].events.push(event);
    } else {
      const { dayName, day, ordinal } = getDateParts(event.startDate);
      const formattedDate = `${dayName} ${day}${ordinal}`;
      eventDateGroups.push({ date: event.startDate, formattedDate: formattedDate, events: [event] });
    }
  });

  eventDateGroups.forEach((eventGroup) => {
    const { month, year } = getDateParts(eventGroup.date);
    const monthAndYear = `${month}-${year}`;
    const i = eventsByMonth.findIndex((g) => g.monthAndYear === monthAndYear);
    if (i > -1) {
      eventsByMonth[i].eventsGroup.push(eventGroup);
    } else {
      eventsByMonth.push({
        monthAndYear: monthAndYear,
        formattedDate: `${titleCase(month)} ${year}`,
        eventsGroup: [eventGroup],
      });
    }
  });

  return (
    <>
      {eventsByMonth.map((monthGroup: EventGroup, m: number) => (
        <div key={m} className='event-tiles_month-group'>
          {!hideDate && <h2>{monthGroup.formattedDate}</h2>}
          {monthGroup.eventsGroup.map((group: EventDateGroup, i: number) => (
            <div key={i} className='event-tiles_event-group'>
              {group.events.map((event: DanceEvent, idx: number) => (
                <EventTile key={idx} event={event} />
              ))}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
