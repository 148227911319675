import React, { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { useFirebaseAuthState, useRemoteConfig, useAlgoliaSearch, useFirestore } from '@bcx-tech/frontend-core';
import { UserProvider } from './contexts/UserContext';
import { getRoutes } from './routes';
import { rootReducer } from './store';
import { DanceEvent, DancerProfile } from '@bcx-tech/tbc-types';

const { REACT_APP_API_DOMAIN: apiDomain } = process.env;

function App() {
  const { remoteConfigRefresh } = useRemoteConfig();
  const [user, loading] = useFirebaseAuthState();
  const router = !loading ? createBrowserRouter(getRoutes({ user })) : null;
  const danceEventSearch = useAlgoliaSearch<DanceEvent>({
    baseUrl: `${apiDomain}/search`,
    endpoint: 'dance-events',
  });
  const dancerProfileFirestoreClient = useFirestore<DancerProfile>({ collection: 'dancer-profiles' });
  const eventsFirestoreClient = useFirestore<DanceEvent>({ collection: 'events' });
  const favouriteEventsFirestoreClient = useFirestore<DanceEvent>({ collection: 'favourite-events' });

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            danceEventSearch,
            dancerProfile: {
              client: dancerProfileFirestoreClient,
            },
            danceEvents: {
              client: eventsFirestoreClient,
            },
            favouriteEvents: {
              client: favouriteEventsFirestoreClient,
            },
          },
        },
      }),
  });

  useEffect(() => {
    remoteConfigRefresh();
  });

  if (!loading) {
    if (user && user.email) {
      window.newrelic.setUserId(user.uid);
    }
  }

  return (
    <Provider store={store}>
      <UserProvider>{!!router && <RouterProvider router={router} />}</UserProvider>
    </Provider>
  );
}

export default App;
