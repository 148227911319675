import React from 'react';
import { Route, redirect, createRoutesFromElements } from 'react-router-dom';
import { User } from 'firebase/auth';

import { LoggedInLayout } from './layouts/LoggedInLayout';
import { SigninLayout } from './layouts/SigninLayout';
import { StartLayout } from './layouts/StartLayout';
import { EventViewLayout } from './layouts/EventViewLayout';
import { Home } from './screens/Home';
import { NotFoundPage } from './screens/NotFoundPage';
import { EventsList } from './screens/EventsList';
import { FavouriteEvents } from './screens/FavouriteEvents';
import { EventView } from './screens/EventView';
import { ProfileView } from './screens/ProfileView';
import { ProfileMediaView } from './screens/ProfileMediaView';
import { PartnerSearch } from './screens/PartnerSearch';
import { MyConnections } from './screens/MyConnections';
import { AchievementsView } from './screens/AchievementsView';

import { Login } from './screens/Login';
import { Signup } from './screens/Signup';
import { SettingsView } from './screens/SettingsView';
import { Start } from './screens/Start';

import { Registration } from './screens/Registration';
import { ProfileEdit, ProfileEditScreen } from './screens/ProfileEdit';
import { ResetPassword } from './screens/ResetPassword';
import { ForgotPassword } from './screens/ForgotPassword';

import { RouterErrorBoundary } from './components/RouterErrorBoundary';

type RoutesProps = {
  user?: User | null | undefined;
};

const loader = async (user?: User | null) => {
  if (!user) {
    return redirect('/start');
  }
  return null;
};

export const getRoutes = ({ user }: RoutesProps) =>
  createRoutesFromElements(
    <Route>
      <Route path='/start' element={<StartLayout />}>
        <Route index element={<Start />} />
      </Route>
      <Route path='/' element={<LoggedInLayout />} loader={async () => await loader(user)}>
        <Route index element={<Home />} errorElement={<RouterErrorBoundary />} />
        <Route path='settings' element={<SettingsView />} />
        <Route path='profile' element={<ProfileView />} errorElement={<RouterErrorBoundary />} />
        <Route path='profile/media' element={<ProfileMediaView />} errorElement={<RouterErrorBoundary />} />
        <Route path='profile/edit' element={<ProfileEditScreen />} errorElement={<RouterErrorBoundary />} />
        <Route path='profile/edit/:stepId' element={<ProfileEdit />} errorElement={<RouterErrorBoundary />} />
        <Route path='partner-search' element={<PartnerSearch />} errorElement={<RouterErrorBoundary />} />
        <Route path='my-connections' element={<MyConnections />} errorElement={<RouterErrorBoundary />} />
        <Route path='achievements' element={<AchievementsView />} errorElement={<RouterErrorBoundary />} />
        <Route path='events' element={<EventsList />} errorElement={<RouterErrorBoundary />} />
        {/* <Route path='events/:eventId' element={<EventView />} errorElement={<RouterErrorBoundary />} /> */}
        <Route path='favourite-events' element={<FavouriteEvents />} errorElement={<RouterErrorBoundary />} />
      </Route>
      <Route path='events/:eventId' element={<EventViewLayout />} loader={async () => await loader(user)}>
        <Route index element={<EventView />} errorElement={<RouterErrorBoundary />} />
      </Route>
      <Route path='/auth' element={<SigninLayout />}>
        <Route path='login' element={<Login />} />
        <Route path='signup' element={<Signup />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='reset-password' element={<ResetPassword />} />
      </Route>
      <Route path='/registration' loader={async () => await loader(user)}>
        <Route index loader={() => redirect('intro')} />
        <Route path=':stepId' element={<Registration />} errorElement={<RouterErrorBoundary />} />
      </Route>
      <Route path='*' element={<NotFoundPage />} />
    </Route>
  );
