import React, { FC, useState } from 'react';
import { Modal, ListGroup, ListGroupItem } from 'react-bootstrap';
import { BsTrash } from 'react-icons/bs';
import { FaVideo } from 'react-icons/fa';
import { PreviewVideo } from '../PreviewVideo';
import { VideoReference } from '@bcx-tech/tbc-types';

import './VideoUploadMenu.scss';

type VideoUploadMenuProps = {
  videoReference: VideoReference;
  handleOnHide: () => void;
  showModal: boolean;
  handleClickUpload: (videoReference: VideoReference) => void;
  handleClickDelete: (videoReference: VideoReference) => void;
};

export const VideoUploadMenu: FC<VideoUploadMenuProps> = ({
  videoReference,
  handleOnHide,
  showModal,
  handleClickUpload,
  handleClickDelete,
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const onClickUpload = async () => {
    await handleClickUpload(videoReference);
    handleOnHide();
  };

  const onClickDelete = async () => {
    await handleClickDelete(videoReference);
    handleOnHide();
  };

  const onClickPreview = async () => {
    setShowPreview(true);
  };

  return (
    <>
      <Modal show={showModal} onHide={handleOnHide} size='lg' className='video-upload-menu'>
        <div className='h-100 d-flex flex-column align-items-center'>
          <ListGroup className='w-100'>
            <ListGroupItem action onClick={onClickPreview}>
              <FaVideo size={20} />
              <span className='image-upload-menu_label'>Play video</span>
            </ListGroupItem>
            <ListGroupItem action onClick={onClickUpload}>
              <FaVideo size={20} />
              <span className='video-upload-menu_label'>Choose a new video</span>
            </ListGroupItem>
            <ListGroupItem action onClick={onClickDelete} id='video-upload-menu_trash'>
              <BsTrash size={20} />
              <span className='video-upload-menu_label'>Delete video</span>
            </ListGroupItem>
            <ListGroupItem action onClick={handleOnHide} className='text-center'>
              <span className='video-upload-menu_label'>Cancel</span>
            </ListGroupItem>
          </ListGroup>
        </div>
      </Modal>
      <PreviewVideo showModal={showPreview} handleOnHide={() => setShowPreview(false)} video={videoReference} />
    </>
  );
};
