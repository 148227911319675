import React, { FC } from 'react';
import { Accordion } from 'react-bootstrap';
import { AddressView } from '@bcx-tech/weave';
import { categoriesList, showDanceOptions } from '@bcx-tech/frontend-core';
import { DanceEvent, Adjudicator, Promoter, FileReference } from '@bcx-tech/tbc-types';
import { RowLabelListView } from '../RowLabelListView';
import { RowLabelView } from '../RowLabelView';
import { SecondaryButton } from '../Buttons';
import './CompetitionView.scss';

type CompetitionViewProps = {
  event: DanceEvent;
  adjudicatorCollection?: Adjudicator[];
  promoterCollection?: Promoter[];
};
export const CompetitionView: FC<CompetitionViewProps> = ({
  event,
  adjudicatorCollection = [],
  promoterCollection,
}) => {
  const categories = event.categories?.map((category) => {
    const currentCategory = categoriesList.filter(({ key }) => key === category.category).reduce((curr) => curr);
    return {
      key: currentCategory.key,
      name: currentCategory.value,
      danceStyles: currentCategory.danceStyles
        .filter(({ value }) => category.danceStyles && category.danceStyles.includes(value))
        .map(({ label }) => label),
      ageLevels: currentCategory.ageCategories
        .filter(({ value }) => category.ageLevels && category.ageLevels.includes(value))
        .map(({ label }) => label),
      showDanceOptions: showDanceOptions
        .filter(({ value }) => category.showDanceOptions && category.showDanceOptions.includes(value))
        .map(({ label }) => label),
      cashPrizes: category.cashPrizes || false,
      showDanceEvent: category.showDanceEvent || false,
      formationEvent: category.formationEvent || false,
      hasSoloRounds: category.hasSoloRounds || false,
      hasOtherInfo: !!category.cashPrizes || !!category.formationEvent || !!category.hasSoloRounds,
    };
  });

  return (
    <Accordion>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>Venue Address and Timings</Accordion.Header>
        <Accordion.Body>
          <AddressView address={event.address}></AddressView>
          <div className='mt-3'>Doors open: {event.time}</div>
          {event.endTime && <div>Finishes at: {event.endTime}</div>}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='1'>
        <Accordion.Header>Description</Accordion.Header>
        <Accordion.Body>{event.description}</Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='2'>
        <Accordion.Header>Categories</Accordion.Header>
        <Accordion.Body>
          {categories &&
            categories.map((category, idx: number) => (
              <div key={idx} className='competition-sections_section'>
                <h3 className='competition-sections_section-name'>{category.name}</h3>
                <div className='d-flex justify-content-between'>
                  <RowLabelListView label='Dance styles' itemArray={category.danceStyles} />
                  <RowLabelListView label='Age categories' itemArray={category.ageLevels} />
                </div>
                {category.showDanceEvent && (
                  <RowLabelListView label='Show dance options' itemArray={category.showDanceOptions} />
                )}
                {category.hasOtherInfo && (
                  <>
                    <span>
                      <strong>Event also includes:</strong>
                    </span>
                    <ul>
                      {['AMATEUR', 'PROFESSIONAL'].includes(category.key) && category.formationEvent && (
                        <li>Formation events</li>
                      )}
                      {category.cashPrizes && <li>Cash prizes</li>}
                      {category.hasSoloRounds && <li>Solo rounds</li>}
                    </ul>
                  </>
                )}
              </div>
            ))}
        </Accordion.Body>
      </Accordion.Item>
      {!!adjudicatorCollection.length && (
        <Accordion.Item eventKey='3'>
          <Accordion.Header>Adjudicators</Accordion.Header>
          <Accordion.Body>
            <RowLabelListView itemArray={adjudicatorCollection.map(({ name }) => name)} />
          </Accordion.Body>
        </Accordion.Item>
      )}
      <Accordion.Item eventKey='4'>
        <Accordion.Header>Promoter and Contacts</Accordion.Header>
        <Accordion.Body>
          {!!promoterCollection?.length && (
            <RowLabelListView label='Promoted by' itemArray={promoterCollection.map(({ name }) => name)} />
          )}
          <RowLabelView label='Phone'>{event.contactPhone}</RowLabelView>
          <div className='d-flex justify-content-center mt-2 mb-2'>
            <SecondaryButton label='Website' href={event.website} className='m-1'></SecondaryButton>
            {event.contactEmail && (
              <SecondaryButton label='Email' href={`mailto:${event.contactEmail}`} className='m-1'></SecondaryButton>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
      {event.attachments && !!event.attachments.length && (
        <Accordion.Item eventKey='5'>
          <Accordion.Header>Event Downloads</Accordion.Header>
          <Accordion.Body>
            <div className='list-group'>
              {event.attachments.map((item: FileReference, i: number) => (
                <a
                  key={i}
                  className='list-group-item list-group-item-action'
                  href={item.url}
                  target='_blank'
                  rel='noreferrer'
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      )}
    </Accordion>
  );
};
