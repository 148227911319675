import React, { FC, useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { Form, Alert } from 'react-bootstrap';
import { Loader } from '@bcx-tech/weave';
import { FileReference, CDNMediaReference, VideoReference } from '@bcx-tech/tbc-types';
import { BsPlus } from 'react-icons/bs';

import './NewVideoUploadField.scss';

const VIDEO_EXTENSIONS = ['mp4', 'm4v', 'mov', 'MOV', 'M4V', 'MP4'];

type NewVideoUploadFieldProps = {
  onChange: (value: VideoReference) => void;
  label?: string;
  uploadFile: (file: File) => Promise<FileReference>;
  getVideoMediaReference: (url: string) => CDNMediaReference;
  allowedExtensions?: string[];
};

export const NewVideoUploadField: FC<NewVideoUploadFieldProps> = ({
  onChange,
  label,
  uploadFile,
  getVideoMediaReference,
  allowedExtensions = VIDEO_EXTENSIONS,
}) => {
  const [currentUpload, setCurrentUpload] = useState<VideoReference>();
  const [error, setError] = useState<{ isError: boolean; message?: string }>({ isError: false });
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    function triggerOnChange(reference: VideoReference) {
      onChange(reference);
    }
    if (currentUpload) {
      triggerOnChange(currentUpload);
    }
  }, [currentUpload]);

  const isFileValid = (file: File): boolean => {
    const ext = file.name.split('.').pop() as string;
    return allowedExtensions.includes(ext);
  };

  const onDrop = async (files: File[]) => {
    if (files.length > 0) {
      const [file] = files;
      if (!isFileValid(file)) {
        setError({ isError: true, message: 'You cannot upload files of this type' });
      } else {
        await upload(file);
      }
    }
  };

  const upload = async (file: File) => {
    try {
      setIsUploading(true);
      const fileReference = await uploadFile(file);
      setIsUploading(false);
      const videoReference: VideoReference = {
        ...fileReference,
        ...getVideoMediaReference(fileReference.url),
      };
      setCurrentUpload(videoReference);
    } catch (err) {
      setError({ isError: true, message: 'Error uploading files' });
    }
  };

  return (
    <div className='video-upload-field'>
      {label && <Form.Label>{label}</Form.Label>}
      <Dropzone onDrop={onDrop} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <div className='my-3'>
            {!isUploading && (
              <div
                {...getRootProps({ className: 'video-upload-field_dropzone d-flex flex-column align-items-center' })}
              >
                <input {...getInputProps()} accept='video/*' />
                <BsPlus className='video-upload-field_upload-icon' size={40} />
              </div>
            )}
            {isUploading && <Loader size='small' />}
            {error.isError && (
              <Alert variant='danger' className='my-2'>
                {error.message}
              </Alert>
            )}
          </div>
        )}
      </Dropzone>
    </div>
  );
};
