import React, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { LoadStatus } from '@bcx-tech/frontend-core';
import { DancerProfile } from '@bcx-tech/tbc-types';
import {
  saveDancerProfile,
  fetchDancerProfileByUserId,
  selectInitDancerProfile,
  selectDancerProfileStatus,
  setFromUser,
} from '../../features/dancerProfileSlice';
import { PrimaryButton } from '../../components/Buttons';
import { DancerProfileFormState, ProfileFormSubmitHandler } from '../../interfaces';
import { UserContext } from '../../contexts/UserContext';
import { profileEditFormConfigs } from './profileEditFormConfigs';

export const ProfileEdit: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState<{ isError: boolean; message?: string }>({ isError: false });
  const dispatch = useDispatch();
  const dancerProfile: DancerProfile | null = useSelector(selectInitDancerProfile);
  const loadStatus = useSelector(selectDancerProfileStatus);
  const { user } = useContext(UserContext);

  const { stepId = '' } = params;

  useEffect(() => {
    if (user && loadStatus === LoadStatus.IDLE) {
      dispatch(fetchDancerProfileByUserId(user.uid));
    }
    if (user && dancerProfile) {
      if (!dancerProfile.uid || !dancerProfile.firstName) {
        dispatch(setFromUser(user));
      }
    }
  }, [user, dancerProfile, dispatch]);

  const { ProfileForm, submitButtonLabel = 'Save changes' } = profileEditFormConfigs[stepId];

  const onSubmit = async (
    e: FormEvent<HTMLFormElement>,
    formState: DancerProfileFormState,
    submitHandler: ProfileFormSubmitHandler
  ) => {
    e.preventDefault();
    const form = e.currentTarget;
    try {
      if (!form.checkValidity()) {
        throw new Error('Make sure you have entered all of the required fields before continuing');
      }
      await submitHandler(formState);
      dispatch(saveDancerProfile());
      navigate('/profile/edit');
    } catch (err) {
      setValidated(false);
      e.stopPropagation();
      const { message } = err as Error;
      setError({ isError: true, message });
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      {error.isError && <Alert variant='danger'>{error.message}</Alert>}
      {loadStatus === LoadStatus.SUCCEEDED && dancerProfile && (
        <ProfileForm validated={validated} dancerProfile={dancerProfile} onSubmit={onSubmit}>
          <div>
            <PrimaryButton className='profile_submit-button w-100 mt-2 mb-3' label={submitButtonLabel} type='submit' />
          </div>
        </ProfileForm>
      )}
    </>
  );
};
