import React, { FC } from 'react';
import { Alert } from 'react-bootstrap';
import { CustomAuthErrorCodes } from '../../interfaces';
import { FirebaseError } from 'firebase/app';

type AuthAlertProps = {
  error: FirebaseError;
};

const AuthAlert: FC<AuthAlertProps> = ({ error }) => {
  let errorMessage: string;
  switch (error.code) {
    case CustomAuthErrorCodes.INVALID_EMAIL:
      errorMessage = 'Enter a valid email address';
      break;
    case CustomAuthErrorCodes.INTERNAL_ERROR:
    case CustomAuthErrorCodes.INVALID_PASSWORD:
    case CustomAuthErrorCodes.USER_NOT_FOUND:
      errorMessage = 'Incorrect email address and/or password';
      break;
    case CustomAuthErrorCodes.USER_NOT_AUTHORIZED:
      errorMessage = 'You are not authorised to log in';
      break;
    case CustomAuthErrorCodes.INVALID_OOB_CODE:
      errorMessage = 'The reset password link not valid. Please try again';
      break;
    default:
      errorMessage = 'An error has occurred. Please try again later';
      break;
  }

  return <Alert variant='danger'>{errorMessage}</Alert>;
};

export type AlertMessageProps = {
  type?: 'danger' | 'warning' | 'info' | 'success';
  message?: string;
  error?: FirebaseError | Error;
};

export const AlertMessage: FC<AlertMessageProps> = ({ type = 'info', message = 'Ok', error, children }) => {
  if (error && error instanceof FirebaseError) {
    return <AuthAlert error={error} />;
  }
  const msg = `${message ? message : ''} ${error && error.message ? ': ' + error.message : ''}`;
  return (
    <Alert variant={type}>
      {msg}
      <div>{children}</div>
    </Alert>
  );
};
