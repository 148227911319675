import React, { FC } from 'react';
import { GeocodeResult } from '@bcx-tech/tbc-types';
import { BsGlobeAmericas } from 'react-icons/bs';

type LocationViewProps = {
  location: GeocodeResult;
};

export const LocationView: FC<LocationViewProps> = ({ location }) => {
  const area = location.address.locality ?? location.address.postalTown ?? location.address.administrativeAreaLevel2;
  const country = location.address.country ?? location.address.administrativeAreaLevel1;
  return (
    <div className='d-flex'>
      <BsGlobeAmericas size={36} />
      <div className='ms-3'>
        {area && <span>{area}, </span>}
        {country && <span>{country}</span>}
      </div>
    </div>
  );
};
