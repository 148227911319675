import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Offcanvas } from 'react-bootstrap';
import { BsFilterCircle, BsFilterCircleFill, BsListUl, BsCalendar3 } from 'react-icons/bs';
import { DanceEventFilters } from '@bcx-tech/tbc-types';
import { useAnalytics, LoadStatus } from '@bcx-tech/frontend-core';
import { lastDayOfMonth } from 'date-fns';
import {
  selectDanceEventSearchStatus,
  searchEvents,
  selectLatestResult,
  selectCurrentSearchResults,
  selectCurrentDateFrom,
  setCurrentSearchTerms,
  resetEventSearch,
  selectHasNextPage,
  setDateFrom,
  selectDanceEventFilters,
  setChosenDate,
  selectChosenDate,
  resetChosenDateToNow,
} from '../../features/danceEventSearchSlice';
import { EventsCalendar } from './EventsCalendar';
import { EventsListAll } from './EventsListAll';
import { EventsFilters } from '../../components/EventsFilters';

import './EventsList.scss';

const iconSize = 24;

export const EventsList: FC = () => {
  const { logAnalyticsEvent } = useAnalytics();
  const [isListView, setIsListView] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
  const dispatch = useDispatch();
  const events = useSelector(selectCurrentSearchResults);
  const danceEventSearchStatus = useSelector(selectDanceEventSearchStatus);
  const latestEvent = useSelector(selectLatestResult);
  const hasNextPage = useSelector(selectHasNextPage);
  const currentDateFrom = useSelector(selectCurrentDateFrom);
  const currentDanceEventFilters = useSelector(selectDanceEventFilters);
  const currentChosenDate = useSelector(selectChosenDate);

  logAnalyticsEvent('page_view', {
    page_title: 'EventsList',
  });

  useEffect(() => {
    if (danceEventSearchStatus === LoadStatus.IDLE) {
      dispatch(searchEvents());
    }
    if (currentDanceEventFilters) {
      setFiltersApplied(true);
    }
  }, [danceEventSearchStatus, currentDanceEventFilters, dispatch]);

  const toggleView = () => {
    setIsListView(!isListView);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleCloseFilters = () => setShowFilters(false);

  const handleFiltersSubmit = (eventFilters: DanceEventFilters) => {
    setShowFilters(false);
    setFiltersApplied(true);
    dispatch(setCurrentSearchTerms(eventFilters));
    dispatch(searchEvents());
  };

  const handleClearFilters = () => {
    setFiltersApplied(false);
    dispatch(resetEventSearch());
    dispatch(searchEvents());
  };

  const handleLoadMore = () => {
    if (hasNextPage) {
      dispatch(searchEvents());
    }
  };

  const handleSetDate = (date: Date) => {
    dispatch(setChosenDate(date.getTime()));
  };

  const handleResetDateToNow = () => {
    dispatch(resetChosenDateToNow());
  };

  const handleActiveStartDate = (activeStartDate: Date) => {
    if (activeStartDate.getTime() < currentDateFrom) {
      dispatch(setDateFrom(activeStartDate.getTime()));
      dispatch(searchEvents());
    }
    if (hasNextPage) {
      if (latestEvent && latestEvent?.startDate < lastDayOfMonth(activeStartDate).getTime()) {
        dispatch(searchEvents());
      }
    }
  };

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div onClick={toggleFilters} className='events-list_icon-button'>
          {filtersApplied ? (
            <BsFilterCircleFill className='events-list_icon-button--selected' size={iconSize} />
          ) : (
            <BsFilterCircle size={iconSize} />
          )}
        </div>
        <div onClick={toggleView} className='events-list_icon-button'>
          {isListView ? <BsCalendar3 size={iconSize} /> : <BsListUl size={iconSize} />}
        </div>
      </div>
      {showFilters && (
        <Offcanvas className='events-list_event-filters' show={showFilters} onHide={handleCloseFilters}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className='events-list_event-filters_title'>Filter Events</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <EventsFilters
              handleSubmit={handleFiltersSubmit}
              handleReset={handleClearFilters}
              savedEventFilters={currentDanceEventFilters}
            />
          </Offcanvas.Body>
        </Offcanvas>
      )}
      {!isListView && (
        <EventsCalendar
          currentChosenDate={currentChosenDate || new Date()}
          events={events}
          filtersApplied={filtersApplied}
          handleActiveStartDate={handleActiveStartDate}
          handleSetDate={handleSetDate}
          handleResetDateToNow={handleResetDateToNow}
        />
      )}
      {isListView && <EventsListAll filtersApplied={filtersApplied} events={events} handleLoadMore={handleLoadMore} />}
    </>
  );
};
