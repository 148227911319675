import React, { FC, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { BsPersonFill, BsCalendar3, BsSearch, BsPeopleFill, BsTrophyFill } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import { LoadStatus } from '@bcx-tech/frontend-core';
import { DanceEvent, DancerProfile } from '@bcx-tech/tbc-types';
import {
  selectIsHomeScreenResultSet,
  selectHomeScreenEvent,
  fetchHomeScreenEvent,
} from '../../features/danceEventSearchSlice';
import {
  fetchDancerProfileByUserId,
  selectDancerProfile,
  selectDancerProfileStatus,
  selectProfilePhoto,
} from '../../features/dancerProfileSlice';
import { UserContext } from '../../contexts/UserContext';
import { LoggedInUser } from '../../components/LoggedInUser';
import { EventTile } from '../../components/EventTiles';

import './Home.scss';

export const Home: FC = () => {
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();
  const homeScreenEvent: DanceEvent | undefined = useSelector(selectHomeScreenEvent);
  const isHomeScreenResultSet = useSelector(selectIsHomeScreenResultSet);
  const dancerProfile: DancerProfile | null = useSelector(selectDancerProfile);
  const loadStatus = useSelector(selectDancerProfileStatus);
  const profilePhoto = useSelector(selectProfilePhoto);

  useEffect(() => {
    if (!isHomeScreenResultSet) {
      dispatch(fetchHomeScreenEvent());
    }
    if (user && loadStatus === LoadStatus.IDLE) {
      dispatch(fetchDancerProfileByUserId(user.uid));
    }
  }, [isHomeScreenResultSet, user, dancerProfile, dispatch]);

  const iconSize = 40;

  const tiles = [
    {
      link: '/profile',
      title: 'Profile',
      icon: <BsPersonFill size={iconSize} />,
    },
    {
      link: '/my-connections',
      title: 'My connections',
      icon: <BsPeopleFill size={iconSize} />,
    },
    {
      link: '/achievements',
      title: 'Achievements',
      icon: <BsTrophyFill size={iconSize} />,
    },
  ];

  return (
    <div className='home'>
      <LoggedInUser profilePhoto={profilePhoto} firstName={user?.firstName} lastName={user?.lastName} />
      {!!homeScreenEvent && (
        <div className='mx-2 mb-3'>
          <h4>Next event</h4>
          <EventTile event={homeScreenEvent} />
        </div>
      )}
      <div className='d-flex flex-wrap justify-content-between align-items-center'>
        <div className='home_primary-tile'>
          <Link to={'/events'} className='home_link'>
            <div className='mb-3'>
              <BsCalendar3 size={50} />
            </div>
            <div>Events Calendar</div>
          </Link>
        </div>
        <div className='home_primary-tile'>
          <Link to={'/partner-search'} className='home_link'>
            <div className='mb-3'>
              <BsSearch size={50} />
            </div>
            <div>Partner Search</div>
          </Link>
        </div>
      </div>
      <div className='d-flex flex-wrap justify-content-around align-items-center'>
        {tiles.map((tile, i) => (
          <div key={i} className='home_tile d-flex flex-column justify-content-center'>
            <Link to={tile.link} className='home_link'>
              <span className='home_tile-icon'>{tile.icon}</span> {tile.title}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
