import React, { FC, useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { Form, Alert } from 'react-bootstrap';
import { Loader } from '@bcx-tech/weave';
import { FileReference, CDNMediaReference, ImageReference, Crop } from '@bcx-tech/tbc-types';
import { BsPlus } from 'react-icons/bs';
import { CropImage } from './CropImage';

import './ImageUploadField.scss';

const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'gif', 'png'];

type ImageUploadFieldProps = {
  onChange: (value: ImageReference | ImageReference[]) => void;
  label?: string;
  uploadFile: (file: File) => Promise<FileReference>;
  getImageMediaReference: (url: string, cropRegion?: Crop) => CDNMediaReference;
  allowedExtensions?: string[];
  allowMultiple?: boolean;
  requireCrop?: boolean;
};

export const ImageUploadField: FC<ImageUploadFieldProps> = ({
  onChange,
  label,
  uploadFile,
  getImageMediaReference,
  allowedExtensions = IMAGE_EXTENSIONS,
  allowMultiple = true,
  requireCrop = false,
}) => {
  const [currentUploads, setCurrentUploads] = useState<ImageReference[]>();
  const [error, setError] = useState<{ isError: boolean; message?: string }>({ isError: false });
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [showCropModal, setShowCropModal] = useState(false);
  const [imageToCrop, setImageToCrop] = useState<File>();

  useEffect(() => {
    function triggerOnChange(references: ImageReference[]) {
      const value = allowMultiple ? references : references[0];
      onChange(value);
    }
    if (currentUploads && currentUploads.length) {
      triggerOnChange(currentUploads);
    }
  }, [currentUploads]);

  const isFileValid = (file: File): boolean => {
    const ext = file.name.split('.').pop() as string;
    return allowedExtensions.includes(ext);
  };

  const hideEditModal = () => {
    setImageToCrop(undefined);
    setShowCropModal(false);
  };

  const onDrop = async (files: File[]) => {
    if (files.length > 0) {
      const [file] = files;
      if (!isFileValid(file)) {
        setError({ isError: true, message: 'You cannot upload files of this type' });
      } else {
        if (requireCrop) {
          setImageToCrop(file);
          setShowCropModal(true);
        } else {
          await upload(file);
        }
      }
    }
  };

  const upload = async (file: File, cropRegion?: Crop) => {
    try {
      setIsUploading(true);
      const fileReference = await uploadFile(file);
      setIsUploading(false);
      const imageReference: ImageReference = {
        ...fileReference,
        ...getImageMediaReference(fileReference.url, cropRegion),
      };
      if (cropRegion) {
        imageReference.cropRegion = cropRegion;
      }
      setImageToCrop(undefined);
      setCurrentUploads((prev = []) => {
        return allowMultiple ? [imageReference, ...prev] : [imageReference];
      });
    } catch (err) {
      setError({ isError: true, message: 'Error uploading files' });
    }
  };

  return (
    <div className='image-upload-field'>
      {label && <Form.Label>{label}</Form.Label>}
      <Dropzone onDrop={onDrop} multiple={allowMultiple}>
        {({ getRootProps, getInputProps }) => (
          <div className='my-3'>
            {!isUploading && (
              <div
                {...getRootProps({ className: 'image-upload-field_dropzone d-flex flex-column align-items-center' })}
              >
                <input {...getInputProps()} accept='image/*' />
                <BsPlus className='image-upload-field_upload-icon' size={40} />
              </div>
            )}
            {isUploading && <Loader size='small' />}
            {error.isError && (
              <Alert variant='danger' className='my-2'>
                {error.message}
              </Alert>
            )}
          </div>
        )}
      </Dropzone>
      {!!imageToCrop && (
        <CropImage
          image={imageToCrop}
          handleOnHide={hideEditModal}
          showModal={showCropModal}
          handleCroppedImage={upload}
        />
      )}
    </div>
  );
};
