enum HeightRange {
  MIN = 140,
  MAX = 200,
}

const INCH = 0.39370079;
const PASSWORD_REX = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;

const convertMetricToImperial = (cm: number) => ({
  feet: Math.floor((cm * INCH) / 12),
  inches: Math.round((cm * INCH) % 12),
});

export const prepareHeightRange = (viewMode: 'metric' | 'imperial' | 'both' = 'both') => {
  const metricRange = [];
  const rawImperialRange = [];
  let currHeight = HeightRange.MIN;
  while (currHeight <= HeightRange.MAX) {
    metricRange.push({ value: currHeight, label: `${currHeight} cm` });
    const { feet, inches } = convertMetricToImperial(currHeight);
    rawImperialRange.push({ value: currHeight, label: `${feet}' ${inches}"` });
    currHeight += 1;
  }
  if (viewMode === 'metric') {
    return metricRange;
  }
  const imperialRange = rawImperialRange.filter(({ label }, idx, ary) => !idx || label !== ary[idx - 1].label) ?? [];
  if (viewMode === 'imperial') {
    return imperialRange;
  }
  return metricRange.map(({ value, label }) => {
    const imp = imperialRange.find(({ value: impValue }) => value === impValue);
    return { value, label: imp ? `${label} (${imp.label})` : label };
  });
};

export const isValidPassword = (password: string): boolean => PASSWORD_REX.test(password);

export const deDupeAndJoin = (arr: (string | undefined)[]): string =>
  arr.filter((c, index) => arr.indexOf(c) === index).join(',');
