import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { MdCake, MdOutlinePermIdentity } from 'react-icons/md';
import { FaRulerHorizontal } from 'react-icons/fa';
import { SingleChoiceField, DateOfBirthField, GenderIdentityField } from '@bcx-tech/weave';
import { useProfile } from '../../hooks/useProfile';
import { prepareHeightRange } from '../../lib/utils';
import {
  setDateOfBirth,
  setGender,
  removeGender,
  setGenderIdentity,
  removeGenderIdentity,
  setHeight,
} from '../../features/dancerProfileSlice';

import { DancerProfileFormProps, DancerProfileFormState } from '../../interfaces';

import './ProfileForms.scss';

const heightChoices = prepareHeightRange();

export const EditPersonalDetailsForm: FC<DancerProfileFormProps> = ({
  dancerProfile,
  onSubmit,
  validated,
  children,
}) => {
  const { formState, createChangeHandler, handleGenderIdentity } = useProfile(dancerProfile);
  const dispatch = useDispatch();

  const submitHandler = (formState: DancerProfileFormState) => {
    dispatch(setDateOfBirth(formState.dateOfBirth));
    if (formState.genderChoice) {
      dispatch(setGender(formState.genderChoice));
      dispatch(removeGenderIdentity());
    } else {
      dispatch(removeGender());
    }
    if (formState.genderIdentity) {
      dispatch(setGenderIdentity(formState.genderIdentity));
    }
    if (formState.height) {
      dispatch(setHeight(formState.height));
    }
  };

  const baseClass = 'profile-form';

  return (
    <Form
      className={baseClass}
      noValidate
      validated={validated}
      onSubmit={(e) => onSubmit(e, formState, submitHandler)}
    >
      <div>
        <MdCake size={48} className={`${baseClass}_form-icon`} />
        <DateOfBirthField
          label='Date of birth'
          dob={dancerProfile.dateOfBirth}
          showAge={false}
          onChange={createChangeHandler('dateOfBirth')}
        />
      </div>
      <div>
        <MdOutlinePermIdentity size={48} className={`${baseClass}_form-icon`} />
        <GenderIdentityField
          label='How do you identify?'
          choice={{
            gender: formState.genderIdentity ? 'CUSTOM' : formState.genderChoice ?? '',
            custom: formState.genderIdentity,
          }}
          onChoose={handleGenderIdentity}
        />
      </div>
      <div>
        <FaRulerHorizontal size={48} className={`${baseClass}_form-icon`} />
        <SingleChoiceField
          label='How tall are you (without heels)?'
          type='select'
          choice={`${dancerProfile?.height}`}
          choices={heightChoices}
          onChoose={createChangeHandler('height')}
        />
      </div>
      {children}
    </Form>
  );
};
