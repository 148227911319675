import React, { FC, useState } from 'react';
import { ImageReference } from '@bcx-tech/tbc-types';
import { ImageUploadField, ImageUploadMenu } from '../ImageUploadField';
import { ProfilePhoto } from '../ProfilePhoto';
import { useStorage } from '../../hooks';

import './ProfilePhotoUpload.scss';

type ProfilePhotoUploadProps = {
  imageFolder: string;
  onChange: (value: ImageReference) => void;
  onDelete: (value: ImageReference) => void | Promise<void>;
  profilePhotoView?: boolean;
  allowedExtensions?: string[];
  currImage?: ImageReference;
};

export const ProfilePhotoUpload: FC<ProfilePhotoUploadProps> = ({
  imageFolder,
  onChange,
  onDelete,
  profilePhotoView = true,
  currImage,
  allowedExtensions,
}) => {
  const [currImageReference, setImageReference] = useState<ImageReference | undefined>(currImage);
  const [showImageUploadMenu, setShowImageUploadMenu] = useState(false);
  const [hideUploadField, setHideUploadField] = useState(!!currImageReference);
  const { uploadFile, getImageMediaReference } = useStorage();

  const handleOnChange = async (newImageReference: ImageReference | ImageReference[]) => {
    if (currImageReference) {
      // delete the previous image
      await onDelete(currImageReference);
    }
    setHideUploadField(true);
    setImageReference(newImageReference as ImageReference);
    onChange(newImageReference as ImageReference);
  };

  const deleteImageReference = async (imageReference: ImageReference) => {
    setImageReference(undefined);
    await onDelete(imageReference);
  };

  return (
    <>
      {!hideUploadField && (
        <ImageUploadField
          onChange={handleOnChange}
          uploadFile={(file) => uploadFile(imageFolder, file)}
          getImageMediaReference={getImageMediaReference}
          allowMultiple={false}
          requireCrop={true}
          allowedExtensions={allowedExtensions}
        />
      )}

      {!!currImageReference && hideUploadField && (
        <>
          {profilePhotoView ? (
            <div className='d-flex flex-wrap justify-content-center mt-3'>
              <div className='profile-photo-container'>
                <ProfilePhoto
                  onClick={() => setShowImageUploadMenu(!showImageUploadMenu)}
                  imageReference={currImageReference}
                />
              </div>
            </div>
          ) : (
            <img src={currImageReference.mediaURL} onClick={() => setShowImageUploadMenu(!showImageUploadMenu)} />
          )}

          <ImageUploadMenu
            imageReference={currImageReference}
            handleClickUpload={() => setHideUploadField(false)}
            handleClickDelete={deleteImageReference}
            showModal={showImageUploadMenu}
            handleOnHide={() => setShowImageUploadMenu(false)}
          />
        </>
      )}
    </>
  );
};
