import React, { FC, useState, useRef, useEffect } from 'react';
import ReactCrop, { PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop';
import { Modal } from 'react-bootstrap';
import { ImageReference, Crop } from '@bcx-tech/tbc-types';
import { PrimaryButton } from '../Buttons';

const TO_RADIANS = Math.PI / 180;

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

type CropImageProps = {
  handleOnHide: () => void;
  showModal: boolean;
  handleCroppedImage: (file: File, cropRegion?: Crop) => void;
  image: File;
  imageReference?: ImageReference;
};

export const CropImage: FC<CropImageProps> = ({ handleOnHide, showModal, handleCroppedImage, image }) => {
  const [crop, setCrop] = useState<Crop>();
  const [scaledCrop, setScaledCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [imgSrc] = useState(URL.createObjectURL(image));

  function CcanvasPreview(image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop, scale = 1, rotate = 0) {
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    // const pixelRatio = window.devicePixelRatio;
    const pixelRatio = 1;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    setScaledCrop({ width: canvas.width, height: canvas.height, x: cropX, y: cropY, unit: 'px' });

    ctx.save();

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 3) Rotate around the origin
    ctx.rotate(rotateRads);
    // 2) Scale the image
    ctx.scale(scale, scale);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);

    ctx.restore();
  }

  useEffect(() => {
    if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
      // We use canvasPreview as it's much faster than imgPreview.
      CcanvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
    }
  }, [completedCrop]);

  const saveProfilePhoto = async () => {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist');
    }

    // previewCanvasRef.current.toBlob((blob) => {
    //   if (!blob) {
    //     throw new Error('Failed to create blob');
    //   }
    //const croppedImg = new File([blob], `cr-${image.name}`, { type: image.type });
    handleCroppedImage(image, scaledCrop);
    handleOnHide();
    //});
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  }

  return (
    <Modal show={showModal} fullscreen={true} onHide={handleOnHide} className='crop-modal'>
      <Modal.Header closeButton closeVariant='white'></Modal.Header>
      <div className='h-100 d-flex flex-column align-items-center'>
        {imgSrc && (
          <>
            <ReactCrop crop={crop} aspect={1} onChange={(_, c) => setCrop(c)} onComplete={(c) => setCompletedCrop(c)}>
              <img
                ref={imgRef}
                className='img-fluid uploadfield-preview-image'
                src={imgSrc}
                alt={image.name}
                onLoad={onImageLoad}
              />
            </ReactCrop>
            <div>Crop and scale your profile photo</div>
          </>
        )}
        <canvas
          ref={previewCanvasRef}
          style={{
            display: 'none',
            border: '1px solid black',
            objectFit: 'contain',
            width: completedCrop?.width,
            height: completedCrop?.height,
          }}
        />
      </div>
      <Modal.Footer>
        <PrimaryButton type='button' label='Set profile photo' handleClick={saveProfilePhoto} />
      </Modal.Footer>
    </Modal>
  );
};
