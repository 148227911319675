import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { SingleChoiceField } from '@bcx-tech/weave';
import { danceRoleChoices } from '@bcx-tech/frontend-core';
import { Form } from 'react-bootstrap';
import { DancingFigureIcon } from '../DancingFigureIcon';
import { useProfile } from '../../hooks';
import { setDanceRolePreferences } from '../../features/dancerProfileSlice';

import { DancerProfileFormProps, DancerProfileFormState } from '../../interfaces';

import './ProfileForms.scss';

export const DanceRolesForm: FC<DancerProfileFormProps> = ({
  validated,
  dancerProfile,
  onSubmit,
  formTitle,
  children,
}) => {
  const { formState, createChangeHandler } = useProfile(dancerProfile);
  const dispatch = useDispatch();

  const submitHandler = (formState: DancerProfileFormState) => {
    if (formState.roleChoice) {
      dispatch(setDanceRolePreferences(formState.roleChoice));
    }
  };

  const baseClass = 'profile-form';

  return (
    <Form
      className={baseClass}
      noValidate
      validated={validated}
      onSubmit={(e) => onSubmit(e, formState, submitHandler)}
    >
      <div className={`${baseClass}_container`}>
        <DancingFigureIcon size={48} className={`${baseClass}_form-icon`} />
        {formTitle && <h2 className={`${baseClass}_title`}>{formTitle}</h2>}
        <SingleChoiceField
          type='block-button'
          choice={formState.roleChoice}
          onChoose={createChangeHandler('roleChoice')}
          choices={danceRoleChoices}
          className='stacked-block-button'
        />
      </div>
      {children}
    </Form>
  );
};
