import React, { FC } from 'react';
import { Accordion } from 'react-bootstrap';
import { FileReference, DanceEvent, Coach } from '@bcx-tech/tbc-types';
import { AddressView } from '@bcx-tech/weave';
import { RowLabelView } from '../RowLabelView';
import { RowLabelListView } from '../RowLabelListView';
import { SecondaryButton } from '../Buttons';

type TrainingCampViewProps = {
  event: DanceEvent;
  coachCollection?: Coach[];
};

export const TrainingCampView: FC<TrainingCampViewProps> = ({ event, coachCollection = [] }) => {
  return (
    <Accordion className='training-camp'>
      <Accordion.Item eventKey='0' className='accordion-item_training-camp'>
        <Accordion.Header>Venue Address and Timings</Accordion.Header>
        <Accordion.Body>
          <AddressView address={event.address}></AddressView>
          <div className='mt-3'>Doors open: {event.time}</div>
          {event.endTime && <div>Finishes at: {event.endTime}</div>}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='1'>
        <Accordion.Header>Description</Accordion.Header>
        <Accordion.Body>{event.description}</Accordion.Body>
      </Accordion.Item>
      {event.pricingDetails && (
        <Accordion.Item eventKey='2'>
          <Accordion.Header>Pricing details</Accordion.Header>
          <Accordion.Body>{event.pricingDetails}</Accordion.Body>
        </Accordion.Item>
      )}
      {!!coachCollection.length && (
        <Accordion.Item eventKey='3'>
          <Accordion.Header>Instructors / Coaches</Accordion.Header>
          <Accordion.Body>
            <RowLabelListView itemArray={coachCollection.map(({ name }) => name)} />
          </Accordion.Body>
        </Accordion.Item>
      )}
      <Accordion.Item eventKey='4'>
        <Accordion.Header>Contact Details</Accordion.Header>
        <Accordion.Body>
          <RowLabelView label='Phone'>{event.contactPhone}</RowLabelView>
          <div className='d-flex justify-content-center mt-2 mb-2'>
            <SecondaryButton label='Website' href={event.website} className='m-1'></SecondaryButton>
            {event.contactEmail && (
              <SecondaryButton label='Email' href={`mailto:${event.contactEmail}`} className='m-1'></SecondaryButton>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
      {event.attachments && !!event.attachments.length && (
        <Accordion.Item eventKey='5'>
          <Accordion.Header>Event Downloads</Accordion.Header>
          <Accordion.Body>
            <div className='list-group'>
              {event.attachments.map((item: FileReference, i: number) => (
                <a
                  key={i}
                  className='list-group-item list-group-item-action'
                  href={item.url}
                  target='_blank'
                  rel='noreferrer'
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      )}
    </Accordion>
  );
};
