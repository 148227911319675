import React, { FC } from 'react';
import { DanceEvent } from '@bcx-tech/tbc-types';
import { EventTiles } from '../../components/EventTiles';
import { PrimaryButton } from '../../components/Buttons';

type EventsListAll = {
  events: DanceEvent[];
  filtersApplied: boolean;
  handleLoadMore: () => void;
};

export const EventsListAll: FC<EventsListAll> = ({ events, filtersApplied, handleLoadMore }) => {
  const today = new Date().getTime();
  const filteredEvents = events.filter(({ startDate }) => startDate > today);

  return (
    <>
      {!!filteredEvents.length && (
        <>
          <EventTiles events={filteredEvents} />
          <div className='mb-3 d-flex justify-content-center'>
            <PrimaryButton label='Load more' handleClick={handleLoadMore} />
          </div>
        </>
      )}
      {!filteredEvents.length && filtersApplied && <span>There are no events matching your search criteria.</span>}
    </>
  );
};
