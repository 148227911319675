import React, { FC, FormEvent, useContext, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Alert, ProgressBar } from 'react-bootstrap';
import { LoadStatus } from '@bcx-tech/frontend-core';
import { DancerProfile } from '@bcx-tech/tbc-types';
import { BsChevronLeft } from 'react-icons/bs';
import {
  selectInitDancerProfile,
  selectDancerProfileStatus,
  fetchDancerProfileByUserId,
  setUid,
  saveDancerProfile,
  setFromUser,
} from '../../features/dancerProfileSlice';
import { Header } from '../../components/Header';
import { PrimaryButton } from '../../components/Buttons';
import { RegistrationIntro } from './RegistrationIntro';
import { DancerProfileFormState, ProfileFormSubmitHandler } from '../../interfaces';
import { UserContext } from '../../contexts/UserContext';
import { RegistrationFormController } from './registrationFormConfigs';

import './Registration.scss';

export const Registration: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [validated, setValidated] = useState(false);
  const [userId, setUserId] = useState<string>();
  const [error, setError] = useState<{ isError: boolean; message?: string }>({ isError: false });
  const dispatch = useDispatch();
  const dancerProfile: DancerProfile | null = useSelector(selectInitDancerProfile);
  const loadStatus = useSelector(selectDancerProfileStatus);
  const { user } = useContext(UserContext);

  const { stepId = 'intro' } = params;

  useEffect(() => {
    if (user && loadStatus === LoadStatus.IDLE) {
      dispatch(fetchDancerProfileByUserId(user.uid));
      dispatch(setFromUser(user));
      setUserId(user.uid);
    }
  }, [user, dispatch]);

  const controller = new RegistrationFormController();
  const formConfigs = controller.getFormConfigs();

  const {
    pageTitle = '',
    RegistrationForm,
    getPrev,
    formTitle = `Hello ${user?.firstName}`,
    submitButtonLabel = 'Next',
    getNext,
    context,
  } = formConfigs[stepId];

  const navigateTo = getNext ? `/registration/${getNext(dancerProfile)}` : '/';
  const currProgress = controller.getFormProgress(stepId);

  const onSubmit = async (
    e: FormEvent<HTMLFormElement>,
    formState: DancerProfileFormState,
    submitHandler: ProfileFormSubmitHandler
  ) => {
    e.preventDefault();
    const form = e.currentTarget;
    try {
      if (!form.checkValidity()) {
        throw new Error('Make sure you have entered all of the required fields before continuing');
      }
      dispatch(setUid(userId));
      submitHandler(formState);
      dispatch(saveDancerProfile());
      const nextForm = getNext ? `/registration/${getNext(formState)}` : '/';
      navigate(nextForm);
    } catch (err) {
      setValidated(false);
      e.stopPropagation();
      const { message } = err as Error;
      setError({ isError: true, message });
      window.scrollTo(0, 0);
    }
  };

  const linkLeft = getPrev
    ? { path: `/registration/${getPrev(dancerProfile)}`, ariaLabel: `Go back`, LinkIcon: BsChevronLeft }
    : undefined;

  const isReady =
    loadStatus === LoadStatus.SUCCEEDED &&
    RegistrationForm &&
    ((stepId === 'step1' && user) || (stepId !== 'step1' && dancerProfile));

  return (
    <>
      <Header
        title={pageTitle}
        linkLeft={linkLeft}
        linkRight={{ path: '/', text: 'Skip', ariaLabel: 'Skip creation of profile' }}
        className='registration_header'
      ></Header>
      <Container className='registration_container'>
        {error.isError && <Alert variant='danger'>{error.message}</Alert>}
        {stepId === 'intro' && (
          <RegistrationIntro formTitle={formTitle}>
            <PrimaryButton
              className='registration_submit-button w-100'
              label='Next'
              handleClick={() => navigate(navigateTo)}
            />
          </RegistrationIntro>
        )}
        {isReady && (
          <>
            <ProgressBar now={currProgress} />
            <RegistrationForm
              formTitle={formTitle}
              validated={validated}
              dancerProfile={dancerProfile}
              context={context}
              onSubmit={onSubmit}
            >
              <PrimaryButton className='registration_submit-button w-100' label={submitButtonLabel} type='submit' />
            </RegistrationForm>
          </>
        )}
      </Container>
    </>
  );
};
