import React, { FC, useState, useEffect } from 'react';
import Calendar, { CalendarTileProperties } from 'react-calendar';
import { getISODate } from '@bcx-tech/frontend-core';
import { DanceEvent } from '@bcx-tech/tbc-types';
import { format } from 'date-fns';
import { EventTiles } from '../../components/EventTiles';
import './Calendar.scss';

type EventCalendarProps = {
  currentChosenDate: Date;
  events: DanceEvent[];
  handleActiveStartDate: (activeStartDate: Date) => void;
  handleSetDate: (date: Date) => void;
  handleResetDateToNow: () => void;
  filtersApplied: boolean;
};

export const EventsCalendar: FC<EventCalendarProps> = ({
  currentChosenDate,
  events,
  filtersApplied,
  handleActiveStartDate,
  handleResetDateToNow,
  handleSetDate,
}) => {
  const [date, setDate] = useState(currentChosenDate);
  const [competitionDates, setCompetitionDates] = useState<string[]>([]);
  const [trainingCampDates, setTrainingCampDates] = useState<string[]>([]);

  useEffect(() => {
    setCompetitionDates(
      events
        .filter(({ eventType }) => eventType === 'COMPETITION')
        .map(({ startDate }) => getISODate(new Date(startDate)))
    );
    setTrainingCampDates(
      events
        .filter(({ eventType }) => eventType === 'TRAININGCAMP')
        .map(({ startDate }) => getISODate(new Date(startDate)))
    );
  }, [events]);

  const filteredEvents = events.filter(({ startDate }) => getISODate(new Date(startDate)) === getISODate(date));

  const onActiveStartDateChange = ({ activeStartDate }: { activeStartDate: Date }) => {
    setDate(activeStartDate);
    handleActiveStartDate(activeStartDate);
  };

  const onSetDate = (date: Date) => {
    setDate(date);
    handleSetDate(date);
  };

  const resetDateToNow = () => {
    setDate(new Date());
    handleResetDateToNow();
  };

  const highlightCalendarTiles = ({ date }: CalendarTileProperties) => {
    const d = getISODate(date);
    if (competitionDates.includes(d) || trainingCampDates.includes(d)) {
      return 'react-calendar__tile--has-event';
    }
    return null;
  };

  const setTileContent = ({ view, date }: CalendarTileProperties) => {
    const d = getISODate(date);
    return view === 'month' ? (
      <span className='dots'>
        {competitionDates.includes(d) && <span className='dot dot--has-competition'></span>}
        {trainingCampDates.includes(d) && <span className='dot dot--has-trainingcamp'></span>}
      </span>
    ) : (
      <></>
    );
  };

  return (
    <div className='calendar-container'>
      <Calendar
        formatShortWeekday={(locale, date) => format(date, 'EEEEE')}
        onActiveStartDateChange={onActiveStartDateChange}
        onChange={onSetDate}
        value={date}
        activeStartDate={date}
        next2Label={null}
        prev2Label={null}
        tileClassName={highlightCalendarTiles}
        tileContent={setTileContent}
        showNeighboringMonth={true}
      />
      <div className='calendar-container_footer-navigation'>
        <div onClick={resetDateToNow}>Today</div>
      </div>
      {!!filteredEvents.length && (
        <>
          {filteredEvents.length > 1 && <h2>{filteredEvents.length} Events:</h2>}
          <EventTiles events={filteredEvents} hideDate={true} />
        </>
      )}
      {!events.length && filtersApplied && <span>There are no events matching your search criteria.</span>}
    </div>
  );
};
