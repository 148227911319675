import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { FaVideo } from 'react-icons/fa';
import { VideoReference } from '@bcx-tech/tbc-types';
import { useStorage, useProfile } from '../../hooks';
import {
  selectVideoFolder,
  setVideos,
  videoExtensions,
  setCoverVideo,
  removeCoverVideo,
} from '../../features/dancerProfileSlice';
import { NewVideoUploadField, VideoUploadMenu } from '../NewVideoUploadField';
import { DancerProfileFormProps, DancerProfileFormState } from '../../interfaces';

import './ProfileForms.scss';

export const VideosForm: FC<DancerProfileFormProps> = ({ validated, dancerProfile, onSubmit, formTitle, children }) => {
  const { uploadFile, deleteFile, getVideoMediaReference } = useStorage();
  const [showVideoUploadMenu, setShowVideoUploadMenu] = useState(false);
  const [context, setContext] = useState<{ videoReference: VideoReference; isCoverVideo: boolean }>();
  const {
    formState,
    handleVideoUpload,
    handleCoverVideoUpload,
    handleCoverVideoRemoval,
    handleVideosRemoval,
    videoReferences,
    videosPendingDeletion,
    isMaxVideos,
  } = useProfile(dancerProfile);
  const dispatch = useDispatch();
  const videoFolder = useSelector(selectVideoFolder);

  const submitHandler = async (formState: DancerProfileFormState) => {
    if (videosPendingDeletion.length) {
      await Promise.all(videosPendingDeletion.map(deleteFile));
    }
    if (videoReferences) {
      dispatch(setVideos(videoReferences));
    }
    if (formState.coverVideo) {
      dispatch(setCoverVideo(formState.coverVideo));
    } else {
      dispatch(removeCoverVideo());
    }
  };

  const showContextMenu = (videoReference: VideoReference, isCoverVideo: boolean) => {
    setShowVideoUploadMenu(true);
    setContext({ videoReference, isCoverVideo });
  };

  const hideContextMenu = () => {
    setShowVideoUploadMenu(false);
    setContext(undefined);
  };

  const baseClass = 'profile-form';

  return (
    <Form
      className={baseClass}
      noValidate
      validated={validated}
      onSubmit={(e) => onSubmit(e, formState, submitHandler)}
    >
      {!!videoFolder && (
        <div className={`${baseClass}_container`}>
          <FaVideo size={48} className={`${baseClass}_form-icon`} />
          {formTitle && <h2 className={`${baseClass}_title`}>{formTitle}</h2>}
          <h3 className={`${baseClass}_text`}>Cover video</h3>
          {!!formState.coverVideo && (
            <div
              key={formState.coverVideo.id}
              className={`${baseClass}_video-container`}
              onClick={() => (formState.coverVideo ? showContextMenu(formState.coverVideo, true) : undefined)}
            >
              <video width='100%'>
                <source src={formState.coverVideo.transformedMediaURL} />
              </video>
            </div>
          )}
          {!formState.coverVideo && (
            <NewVideoUploadField
              uploadFile={(file) => uploadFile(videoFolder, file)}
              getVideoMediaReference={getVideoMediaReference}
              onChange={handleCoverVideoUpload}
              allowedExtensions={videoExtensions}
            />
          )}
          <h3 className={`${baseClass}_text`}>Profile videos</h3>
          <p className={`${baseClass}_text`}>You can add up to 2 videos.</p>
          {videoReferences &&
            videoReferences.map((video) => (
              <div
                key={video.id}
                className={`${baseClass}_video-container`}
                onClick={() => showContextMenu(video, false)}
              >
                <video width='100%'>
                  <source src={video.transformedMediaURL} />
                </video>
              </div>
            ))}
          {!isMaxVideos() && (
            <NewVideoUploadField
              uploadFile={(file) => uploadFile(videoFolder, file)}
              getVideoMediaReference={getVideoMediaReference}
              onChange={handleVideoUpload}
              allowedExtensions={videoExtensions}
            />
          )}
        </div>
      )}
      {showVideoUploadMenu && context && (
        <VideoUploadMenu
          videoReference={context.videoReference}
          handleClickUpload={context.isCoverVideo ? handleCoverVideoRemoval : handleVideosRemoval}
          handleClickDelete={context.isCoverVideo ? handleCoverVideoRemoval : handleVideosRemoval}
          showModal={showVideoUploadMenu}
          handleOnHide={hideContextMenu}
        />
      )}
      {!!formTitle && <p className={`${baseClass}_text`}>You can change this later.</p>}
      {children}
    </Form>
  );
};
